<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import api from '@/api';
import dayjs from 'dayjs';
import TechnicalLiteratureFeed from '@/components/common/technical-literature/technical-literature-feed.vue';
import { FeedType, ThemeFeedResearchPaper } from '@/types';
import {
  STATES,
  useTheme,
  useThemeResearchPaperFeed,
  useUserInfo,
} from '@/utils/swr';
import { useStore } from '@/utils/vue';
import { featureFlags } from '@/featureFlags';

const router = useRouter();
const route = useRoute();
const store = useStore();

const { data: userInfo } = useUserInfo();
const feedType = computed(() => store.state.feedType.feedType as FeedType);
const themeId = computed(() => Number(route.params.themeId));
const { data: themeResponse } = useTheme(themeId.value);
const theme = computed(() => themeResponse.value?.themes);

const isForeignMediaEnabled = computed(
  () => theme.value?.is_foreign_media_enabled ?? false,
);
const isKeywordFeedFromSearchEnabled =
  featureFlags.ANDEV_5508_KEYWORD_FEED_FROM_SEARCH;

const keywordFeedType = ref<'keyword_feed' | 'keyword_feed_from_search'>(
  route.query.keywordFeedType === 'keyword_feed_from_search' &&
    isKeywordFeedFromSearchEnabled
    ? 'keyword_feed_from_search'
    : 'keyword_feed',
);
const toggleKeywordFeedType = () => {
  if (!isKeywordFeedFromSearchEnabled) {
    return;
  }
  keywordFeedType.value =
    keywordFeedType.value === 'keyword_feed_from_search'
      ? 'keyword_feed'
      : 'keyword_feed_from_search';
};

watch(keywordFeedType, async newType => {
  router.replace({ query: { ...route.query, keywordFeedType: newType } });
  await updateDomesticFeedFromSearch();
  await updateForeignFeedFromSearch();
});

const domesticFeedFromSearch = ref<{
  articles: ThemeFeedResearchPaper[];
  count: number;
}>();

const updateDomesticFeedFromSearch = async () => {
  if (
    isKeywordFeedFromSearchEnabled &&
    keywordFeedType.value === 'keyword_feed_from_search'
  ) {
    domesticFeedFromSearch.value = (await api.getThemeKeywordFeedsFromSearch(
      themeId.value,
      'research_paper',
      'domestic',
      dayjs().subtract(1, 'day'),
      1,
      200,
    )) as { articles: ThemeFeedResearchPaper[]; count: number };
  }
};

const foreignFeedFromSearch = ref<{
  articles: ThemeFeedResearchPaper[];
  count: number;
}>();

const updateForeignFeedFromSearch = async () => {
  if (
    isKeywordFeedFromSearchEnabled &&
    keywordFeedType.value === 'keyword_feed_from_search'
  ) {
    foreignFeedFromSearch.value = (await api.getThemeKeywordFeedsFromSearch(
      themeId.value,
      'research_paper',
      'foreign',
      dayjs().subtract(1, 'day'),
      1,
      200,
    )) as { articles: ThemeFeedResearchPaper[]; count: number };
  }
};

const feedCount = computed(() => {
  if (feedType.value === 'foreign' && !isForeignMediaEnabled.value) {
    // 海外メディア設定がオフの場合、カウントを表示しない
    return undefined;
  }
  if (
    isKeywordFeedFromSearchEnabled &&
    keywordFeedType.value === 'keyword_feed_from_search' &&
    domesticFeedFromSearch.value &&
    foreignFeedFromSearch.value
  ) {
    return feedType.value === 'all'
      ? domesticFeedFromSearch.value.count + foreignFeedFromSearch.value.count
      : feedType.value === 'domestic'
        ? domesticFeedFromSearch.value.count
        : foreignFeedFromSearch.value.count;
  }
  const counts = theme.value?.research_paper_feed_counts ?? {
    domestic: 0,
    foreign: 0,
  };
  return feedType.value === 'all'
    ? counts.domestic + counts.foreign
    : counts[feedType.value];
});

const redirectToKeywordFeed = () => {
  router.push({
    name: 'themeFeed',
    params: { themeId: String(themeId.value) },
  });
};

watch(
  () => theme.value,
  () => {
    if (theme.value && !theme.value?.is_research_paper_enabled) {
      redirectToKeywordFeed();
    }
  },
);

onMounted(async () => {
  api.trackPageView({
    pageName: 'research_papers',
    pageUrl: route.fullPath,
    theme: {
      id: themeId.value,
    },
    feedType: feedType.value,
  });
  await updateDomesticFeedFromSearch();
  await updateForeignFeedFromSearch();
});

const enableToEditTheme = computed(
  () =>
    userInfo.value?.role !== 'viewer' ||
    (theme.value?.access_scope === 'personal' &&
      theme.value?.user_id === userInfo.value?.id),
);

// useThemeResearchPaperFeedは表示するメディア（`feedType`）と取得するフィード（'domestic'・'foreign'）を
// 参照してリクエストするかどうかを判定する
// たとえばfeedType=foreignと'domestic'の場合、keyはundefinedになってリクエストを発生しない
// undefinedの場合、stateはpendingになるのでテンプレートも表示するメディアも確認する
const { data: domesticResponse, state: domesticState } =
  useThemeResearchPaperFeed(feedType, themeId.value, 'domestic', ref(1), 200);
const { data: foreignResponse, state: foreignState } =
  useThemeResearchPaperFeed(feedType, themeId.value, 'foreign', ref(1), 200);

const domesticPapers = computed(() =>
  isKeywordFeedFromSearchEnabled &&
  keywordFeedType.value === 'keyword_feed_from_search' &&
  domesticFeedFromSearch.value
    ? {
        items: domesticFeedFromSearch.value.articles,
        state: STATES.SUCCESS,
      }
    : {
        items:
          domesticResponse.value?.research_paper_feeds ??
          ([] as ThemeFeedResearchPaper[]),
        state: domesticState.value,
      },
);

const foreignPapers = computed(() =>
  isKeywordFeedFromSearchEnabled &&
  keywordFeedType.value === 'keyword_feed_from_search' &&
  foreignFeedFromSearch.value
    ? {
        items: foreignFeedFromSearch.value.articles,
        state: STATES.SUCCESS,
      }
    : {
        items:
          foreignResponse.value?.research_paper_feeds ??
          ([] as ThemeFeedResearchPaper[]),
        state: foreignState.value,
      },
);

const feedDate = computed(
  () => theme.value?.research_paper_feed_last_updated_at ?? undefined,
);
</script>

<template>
  <TechnicalLiteratureFeed
    v-if="theme"
    feed="theme"
    doc-type="research_paper"
    :feed-type="feedType"
    :feed-count="feedCount"
    :feed-date="feedDate"
    :domestic-documents="domesticPapers"
    :foreign-documents="foreignPapers"
    :theme="theme"
    :enable-to-edit-theme="enableToEditTheme"
    :keyword-feed-type="keywordFeedType"
    :toggle-keyword-feed-type="toggleKeywordFeedType"
  />
</template>

<style lang="scss" scoped></style>
