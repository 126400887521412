import { RouteRecordRaw } from 'vue-router';
import { featureFlags } from '@/featureFlags';

export const accountRouteConfig: RouteRecordRaw = {
  path: '/account',
  component: () => import('@/components/layouts/account.vue'),
  children: [
    {
      path: 'login',
      component: () => import('@/components/account/login.vue'),
      name: 'login',
      meta: {
        title: 'ログイン',
        type: 'account',
        desc: 'こちらはAnewsのログインページです。Anewsをご利用中の方はこちらからログインしてください',
      },
    },
    {
      path: 'forgot_password',
      component: () => import('@/components/account/forgot-password.vue'),
      name: 'forgotPassword',
      meta: { title: 'パスワードを忘れてしまった場合', type: 'account' },
    },
    {
      path: 'new_password',
      component: () => import('@/components/account/new-password.vue'),
      name: 'newPassword',
      meta: { title: 'パスワード設定', type: 'account' },
    },
    {
      path: 'confirmForgotPassword',
      component: () =>
        import('@/components/account/confirm-forgot-password.vue'),
      name: 'confirmForgotPassword',
      meta: { title: '新しいパスワードを設定', type: 'account' },
    },
    ...(featureFlags.ANDEV_5698_SAML_LOGIN
      ? [
          {
            path: 'saml/login',
            component: () => import('@/components/account/saml-login.vue'),
            name: 'samlLogin',
            meta: {
              title: 'SSOログイン',
              type: 'account',
              desc: 'こちらはAnewsのログインページです。Anewsをご利用中の方はこちらからログインしてください',
            },
          },
          {
            path: 'saml/callback',
            component: () => import('@/components/account/saml-callback.vue'),
            name: 'samlCallback',
            meta: {
              title: 'ログイン',
              type: 'account',
              desc: 'こちらはAnewsのログインページです。Anewsをご利用中の方はこちらからログインしてください',
            },
          },
        ]
      : []),
  ],
};
