import { IconTypes } from '@stockmarkteam/donguri-ui';
import { Comment, GroupMark, Mark } from '@/types';

export type DocType = 'article' | 'research_paper' | 'patent' | 'report';
export type Lang = 'ja' | 'en' | 'zh';
export type ThemeFeedType = 'domestic' | 'foreign';
export type FeedType = 'all' | ThemeFeedType;

export const isDocType = (docType: string | null): docType is DocType =>
  docType === 'article' ||
  docType === 'research_paper' ||
  docType === 'patent' ||
  docType === 'report';

export type AdpDocumentBasicInfo = {
  id?: number;
  title: string;
  translated_title?: string;
  lang: Lang;
  media_name?: string;
  journal_name?: string;
  is_priority_journal?: boolean;
  published_at?: string;
  url: string;
  pdf_url?: string | null;
  image_url?: string | null;
  doc_type: DocType;
  is_read?: boolean;
  rank?: number;
  content?: string;
  is_alliance_media?: boolean;
};

type ArticleBase = {
  id: number;
  media_name: string;
  url: string;
  pdf_url: string | null;
  image_url: string;
  title: string;
  content: string;
  published_at: string;
  comments: Comment[];
  marks: Mark[];
  group_marks: GroupMark[];
  theme_id: number;
  rank: number;
  hit_keywords: string[];
  synonym_keywords?: string[];
  login_required?: boolean;
  memo?: string;
  tags?: string[];
  is_read: boolean;
  lang: Lang;
  translated_title?: string;
  cluster_number?: number;
  topic_id?: number;
  doc_type: 'article';
  is_alliance_media?: boolean;
  text?: string;
  related_articles_available?: boolean;
  category: string;
};

type NewsCategoryArticleFieldsNull = {
  news_categories: null;
  pest_category: null;
};

type NewsCategoryArticleFieldsEmpty = {
  news_categories: never[];
  pest_category: '';
};

type NewsCategoryArticleFieldsBasic = {
  news_categories: [string, ...string[]];
  pest_category: '政治' | '経済' | '社会' | '技術' | '';
};

/**
 * ### 記事のカテゴリ情報
 * - news_categories: [NewsCategoryClassification](https://github.com/stockmarkteam/NewsCategoryClassification/tree/news-category/phase-1?tab=readme-ov-file#%E3%82%AB%E3%83%86%E3%82%B4%E3%83%AA%E4%B8%80%E8%A6%A7)に基づき付与される
 * - pest_category: PEST分類にもとづき`政治` `経済` `社会` `技術`のいずれかの文字列が入る
 * （＊news_categoriesが根拠となっており、news_categoriesがnullであればnull、空配列または要素があってもPESTに関連がないものだけの場合は空文字となる）
 */
type NewsCategoryArticleFields =
  | NewsCategoryArticleFieldsNull
  | NewsCategoryArticleFieldsBasic
  | NewsCategoryArticleFieldsEmpty;

export type Article = ArticleBase & NewsCategoryArticleFields;

export type ArticleUpdateOption = {
  shouldUpdateMemo: boolean;
  shouldUpdateTags: boolean;
};

export type ResearchPaperBase = {
  id: number;
  url: string;
  pdf_url: string | null;
  image_url: string;
  title: string;
  translated_title?: string;
  journal_name: string;
  is_priority_journal: boolean;
  published_at: string;
  marks: Mark[];
  group_marks: GroupMark[];
  comments: Comment[];
  is_read: boolean;
  lang: Lang;
  doc_type: 'research_paper';
  created_at: string;
  hit_keywords?: string[];
  synonym_keywords?: string[];
  theme_id?: number;
  memo?: string;
  tags?: string[];
};

export type ThemeFeedResearchPaper = ResearchPaperBase & {
  theme_id: number;
  rank: number;
  is_new: boolean;
};

type PersonalFeedResearchPaper = ResearchPaperBase & {
  rank: number;
};

type MarkedResearchPaper = ResearchPaperBase & {
  memo: string;
  tags: string[];
};

export type ResearchPaper =
  | ResearchPaperBase
  | ThemeFeedResearchPaper
  | PersonalFeedResearchPaper
  | MarkedResearchPaper;

export type PatentBase = {
  id: number;
  url: string;
  pdf_url: string | null;
  image_url: string;
  title: string;
  translated_title?: string;
  patent_identifier: string;
  issue: string | null;
  applicant: string;
  published_at: string;
  marks: Mark[];
  group_marks: GroupMark[];
  comments: Comment[];
  is_read: boolean;
  lang: Lang;
  doc_type: 'patent';
  created_at: string;
  hit_keywords?: string[];
  synonym_keywords?: string[];
  theme_id?: number;
  memo?: string;
  tags?: string[];
};

export type ThemeFeedPatent = PatentBase & {
  theme_id: number;
  rank: number;
  is_new: boolean;
};

type MarkedPatent = PatentBase & {
  memo: string;
  tags: string[];
};

type Patent = PatentBase | ThemeFeedPatent | MarkedPatent;

type ReportBase = {
  id: number;
  url: string;
  image_url: string;
  title: string;
  translated_title?: string;
  published_at: string;
  is_read: boolean;
  lang: Lang;
  doc_type: 'report';
  created_at: string;
  hit_keywords?: string[];
  synonym_keywords?: string[];
  site_name: string;
  theme_id?: number;
  memo?: string;
  tags?: string[];
  comments: Comment[];
  marks: Mark[];
  group_marks: GroupMark[];
  page_number?: number;
  chunk_text?: string;
};

export type Report = ReportBase;

export type UserDocument = UserDocumentEntity & {
  similar_documents: UserDocumentEntity[];
};

export type FileType = 'PDF' | 'Word' | 'PowerPoint' | 'unknown';

export type UserDocumentEntity = {
  id: string; // ULID
  url: string;
  image_url: string;
  title: string;
  page_number?: number;
  is_read: boolean;
  lang: Lang;
  datasource_id: string;
  storage_type: string;
  storage_file_id: string;
  filename: string;
  path: string;
  folder_url: string;
  last_updated_by: string;
  modified_at: string;
  doc_type: 'user_document';
  file_type: FileType;
  chunk_text: string;
  hit_keywords?: string[];
  synonym_keywords?: string[];
  file_total_pages: number;
  preview_image_path_template: string;
};

export type ViewedArticle = Article & {
  article_exclusion_id: number | null;
};

export type KeywordFeed = Article & {
  feed_date: string;
  feed_job_code: string;
  article_id: number;
};

// Documentはbuilt-in type (HTML doc) なのでADPから取得したデータはAdpDocumentになる
export type AdpDocument = Article | ResearchPaper | Patent | Report;

export function isDocument(
  targetDocument: AdpDocument | UserDocument | AdpDocumentBasicInfo,
) {
  return targetDocument.id !== undefined;
}

export function isAdpDocument(
  targetDocument: AdpDocument | UserDocument | AdpDocumentBasicInfo,
) {
  return (
    isArticle(targetDocument) ||
    isResearchPaper(targetDocument) ||
    isPatent(targetDocument) ||
    isReport(targetDocument)
  );
}

export function isUserDocument(
  targetDocument: AdpDocument | UserDocument | AdpDocumentBasicInfo,
): targetDocument is UserDocument {
  return targetDocument.doc_type === 'user_document';
}

export function isArticle(
  adpDocument: AdpDocument | AdpDocumentBasicInfo | UserDocument | undefined,
): adpDocument is Article {
  return adpDocument?.doc_type === 'article';
}

export function isResearchPaper(
  adpDocument: AdpDocument | AdpDocumentBasicInfo | UserDocument | undefined,
): adpDocument is ResearchPaper {
  return adpDocument?.doc_type === 'research_paper';
}

export function isPatent(
  adpDocument: AdpDocument | AdpDocumentBasicInfo | UserDocument | undefined,
): adpDocument is Patent {
  return adpDocument?.doc_type === 'patent';
}

export function isReport(
  adpDocument: AdpDocument | AdpDocumentBasicInfo | UserDocument | undefined,
): adpDocument is Report {
  return adpDocument?.doc_type === 'report';
}

export type IndustryArticle = Article & {
  topic_id: number;
  case_study_text: string;
  trend_company_text: string;
};

export type UrlPreview = {
  title?: string;
  url?: string;
  image_url?: string;
  media_name?: string;
  published_at?: string;
};

export type UrlPreviewWithInputUrl = UrlPreview & { input_url: string };

export type PutExcludeSiteAndArticleError = {
  error: string;
};

export type RelatedArticlesResponse = {
  articles: Article[];
  search_query: string;
  request_id: string;
};

export type AdpDocumentOptionAction = {
  label: string;
  subDescription?: string;
  icon: IconTypes;
  action: () => void;
};
