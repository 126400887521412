<script setup lang="ts">
/**
 * スレッドを表すコンポーネント
 */
import { computed, nextTick, onMounted, provide, ref } from 'vue';
import {
  LocationQueryValue,
  onBeforeRouteUpdate,
  useRoute,
  useRouter,
} from 'vue-router';
import api from '@/api';
import { trackingSurveyEvent } from '@/api/tracking';
import { marked } from 'marked';
import sanitizeHtml from 'sanitize-html';
import Header from '@/components/layouts/header.vue';
import SurveyInput from '@/components/survey/input/survey-input.vue';
import SurveySessionSwitcherInput from '@/components/survey/input/survey-session-switcher-input.vue';
import SurveyHistory from '@/components/survey/survey-history.vue';
import SurveyRelatedQuestions from '@/components/survey/survey-related-questions.vue';
import {
  AdpDocument,
  FilterInfo,
  Part,
  PostSurveyResponse,
  SearchScope,
  SurveyExecutionFrom,
  SurveyHistoryData,
  SurveyInputValue,
  UserDocument,
} from '@/types';
import { useUserDocumentActionHistories } from '@/utils/composables/useUserDocumentActionHistories';
import { closeAllDocumentsKey } from '@/utils/injectionKeys';
import { sanitize } from '@/utils/sanitize';
import {
  convertKeysToCamelCase,
  getDocTypesQueryParam,
  getSpecifiedFilesQueryParam,
} from '@/utils/survey/common';
import {
  ALL_DOC_TYPES,
  DOC_TYPES_FOR_SCOPE,
  STREAMING_DATA_TYPES,
  SURVEY_PAGE_NAME,
} from '@/utils/survey/constants';
import { processNode } from '@/utils/survey/domParser';
import { useTeamInfo } from '@/utils/swr';
import { userSession } from '@/utils/userSession';
import { useEmitter, useStore } from '@/utils/vue';
import { featureFlags } from '@/featureFlags';

const emitter = useEmitter();
const route = useRoute();
const router = useRouter();
const store = useStore();
const SESSION_LIMIT_COUNT = 50; // 1スレッドで生成できる要約の上限数

const isUserMarkSurveyEnabled = featureFlags.ANDEV_5481_USER_MARK_SURVEY;

/**
 * marked.jsのRendererをカスタマイズ
 *
 * - 行単位で返ってきたデータを毎回全量でparseするため、結果が変わらないようにするためのカスタムrenderer
 * - li要素が複数ある場合に、1個目のli要素内が最初は`<p>`タグで囲まれず、2個目のli要素が追加されたタイミングで、
 *  `<p>`タグで囲まれてしまう問題を解消する
 *
 * 詳細ログ: https://anews.atlassian.net/browse/ANDEV-4736?focusedCommentId=40605
 */
const renderer = new marked.Renderer();
renderer.listitem = item => {
  const text = item.text.trim();
  return `<li>${marked(text)}</li>\n`;
};
marked.setOptions({
  renderer,
});

/**
 * サニタイズの設定
 */
const MarkdownToHtmlOptions = {
  allowedTags: sanitizeHtml.defaults.allowedTags, // 'img'タグは含まれていない
  allowedAttributes: {
    ...sanitizeHtml.defaults.allowedAttributes,
    a: ['href', 'target', 'style', 'class'],
  },
};

/* 契約状態 */
const { data: teamInfo } = useTeamInfo();
const enableTechnicalLiterature = computed(
  () => teamInfo.value?.enable_technical_literature ?? false,
);
const enableUserDocument = computed(
  () => teamInfo.value?.enable_user_document ?? false,
);

/* 要約関連のstate */
const surveySessionId = ref<string>(route.params.survey_session_id as string); // surveysテーブルのuuid
const surveyHistories = ref<Array<SurveyHistoryData>>([]);
const sources = ref<Array<AdpDocument | UserDocument>>([]); // 要約生成のためにモデルに渡したソース
let originMarkdown = ''; // 1行単位のレスポンスデータ (マークダウン形式の文字列) が追加されていく元データ
const contentParts = ref<Part[]>([]); // サーバから返ってきたマークダウン形式の要約をパースしたもの
const latestSurveyHistory = computed(() => surveyHistories.value.slice(-1)[0]);
const relatedQuestions = ref<string[]>([]);
const question = ref<string>('');
const cached_expanded_queries = ref<string[]>([]); // クエリ変換・検索なし生成によってスレッドごとに（スレッド内でも実行タイミングによって文脈が変わり） 適切な文脈を保持した拡張クエリでないといけないため、要約では一旦拡張クエリのキャッシュはしない
const filterInfo = ref<FilterInfo>({
  searchScope: 'all',
  docTypes: [],
  specifiedFiles: [],
});
// 計測で使用: 何のドキュメントを対象に要約（の中の検索）が行われたか
const docTypesToUse = computed(() => {
  return filterInfo.value.docTypes.length === 0
    ? isUserMarkSurveyEnabled
      ? DOC_TYPES_FOR_SCOPE(
          filterInfo.value.searchScope,
          enableTechnicalLiterature.value,
          enableUserDocument.value,
        )
      : ALL_DOC_TYPES(enableTechnicalLiterature.value, enableUserDocument.value)
    : filterInfo.value.docTypes;
});

/* 表示制御に関するフラグstate */
const isLoaded = ref(false);
const isClosingAllDocuments = ref(false);
const isSurveyInProgress = computed(() => {
  return store.getters['survey/isSurveyInProgress'](surveySessionId.value);
}); // スレッド (survey-session.vue)内 でSurveyを実行中かどうか
const isFetchingHistories = ref<boolean>(false); // スレッドに紐づく要約の履歴を取得中かどうか

/* ファイル指定履歴 */
const { createSpecifiedFileHistories } = useUserDocumentActionHistories(
  userSession.getUserId(),
);

const startSurvey = async ({
  question: emitQuestion,
  filterInfo: emitFilterInfo,
}: SurveyInputValue) => {
  question.value = emitQuestion;
  filterInfo.value = emitFilterInfo;

  await submitSurveyStream(true);
};

const startSurveyFromRelatedQuestion = async (selectedQuestion: string) => {
  question.value = selectedQuestion;
  filterInfo.value = {
    searchScope: latestSurveyHistory.value?.filterInfo.searchScope ?? 'all',
    docTypes: latestSurveyHistory.value?.filterInfo.docTypes ?? [],
    specifiedFiles: latestSurveyHistory.value?.filterInfo.specifiedFiles ?? [],
  };
  await submitSurveyStream(true);
};

/**
 * Surveyの実行開始時の初期化処理
 */
const initializeSurveyState = () => {
  originMarkdown = '';
  sources.value = [];
  contentParts.value = [];
  relatedQuestions.value = [];
  isClosingAllDocuments.value = true; // 新たに要約を生成する際、"すべて表示"で展開しているスレッド内のすべてのhistoryのドキュメントを折りたたむ
};

/**
 * ドキュメントの更新
 * `src/components/survey/document/survey-adp-document-card.vue`でマークを行うとemitで呼び出される
 *
 * @param {number} historyIndex - surveyHistoriesのindex
 * @param {number} sourceIndex - sourcesのindex
 * @param {AdpDocument} document - 更新するドキュメント
 */
const updateDocument = (
  historyIndex: number,
  sourceIndex: number,
  document: AdpDocument,
) => {
  // NOTE: 表示に関わる値の変更は`updateSurveyHistory`関数に責務を持たせたいので、ここでは直接更新はせず一時的な変数を用意
  const updatedSources = [...surveyHistories.value[historyIndex].sources];
  updatedSources[sourceIndex] = document;

  updateSurveyHistory(historyIndex, { sources: updatedSources });
};

/**
 * 特定のインデックスの要素を更新する関数
 * @param {number} index - 更新する要素のインデックス
 * @param {Partial<SurveyHistoryData>} updates - 更新内容のオブジェクト
 */
const updateSurveyHistory = (
  index: number,
  updates: Partial<SurveyHistoryData>,
) => {
  if (index >= 0 && index < surveyHistories.value.length) {
    surveyHistories.value[index] = {
      ...surveyHistories.value[index],
      ...updates,
    };
  }
};

/**
 * チャンクデータを処理する関数
 * @param {PostSurveyResponse} chunk - APIからのチャンクデータ
 * @param {number} surveyIndex - 現在のsurveyのインデックス
 */
const handleChunk = (chunk: PostSurveyResponse, surveyIndex: number): void => {
  switch (chunk.type) {
    case STREAMING_DATA_TYPES.ERROR:
      updateSurveyHistory(surveyIndex, { isError: true });
      break;
    // 検索結果が0件
    case STREAMING_DATA_TYPES.NO_HIT_SOURCES:
      updateSurveyHistory(surveyIndex, { isNoHitSources: true });
      break;
    // ソース取得完了 (ソース部分だけ先に描画)
    case STREAMING_DATA_TYPES.SOURCES_DONE:
      updateSurveyHistory(surveyIndex, { sources: sources.value });
      break;
    // ソース取得
    case STREAMING_DATA_TYPES.SOURCE:
      sources.value.push(chunk);
      break;
    // 要約取得
    case STREAMING_DATA_TYPES.TOKEN: {
      originMarkdown += chunk.content;
      const markdownToHtml = marked.parse(originMarkdown) as string; // ネストした箇条書きなどを考慮するため、追加された行だけではなく全量を parse する
      const sanitizedHtml = sanitize(markdownToHtml, MarkdownToHtmlOptions);
      const parser = new DOMParser();
      const doc = parser.parseFromString(sanitizedHtml, 'text/html');
      const { processedParts } = processNode(doc.body, 0, 1, sources.value);
      contentParts.value = processedParts;
      updateSurveyHistory(surveyIndex, {
        summary: contentParts.value,
        requestId: chunk.request_id,
      });
      break;
    }
    // 要約完了
    case STREAMING_DATA_TYPES.SURVEY_DONE:
      updateSurveyHistory(surveyIndex, {
        originMarkdownSummary: originMarkdown,
        isInProgressSurvey: false,
      });
      break;
    // 関連質問
    case STREAMING_DATA_TYPES.RELATED_QUESTIONS:
      relatedQuestions.value = chunk.related_questions;
      break;
    // `src/api/survey.ts`で`STREAMING_DATA_TYPES`に未定義のtypeは無視する作りになっているため、defaultには来ない
    default:
      break;
  }
};

/**
 * Surveyを実行 ( 「questionで検索を実行し、その結果をもとに要約を生成」を行うAPI呼び出しと、レスポンスのハンドリング )
 * @param {boolean} isNew - 新規surveyかどうか (v1以降の機能で、過去のquestionを編集して再度surveyを実行する想定があるため最初から対応)
 * @param {number} index - surveyHistoriesのindex (isNewがfalseの場合、過去のsurveyを編集するために使用する該当データのindex)
 */
const submitSurveyStream = async (isNew: boolean, index?: number) => {
  // NOTE: 計測で使用する前に画面遷移されると別の値になるため、survey実行後すぐのこの段階で取得する
  const sessionId = surveySessionId.value;
  // 初期化
  initializeSurveyState();
  if (isNew) {
    surveyHistories.value.push({
      requestId: '',
      question: question.value,
      sources: [],
      originMarkdownSummary: '',
      summary: [],
      isNoHitSources: false,
      isError: false,
      isInProgressSurvey: true,
      enableAnimation: true,
      feedbackType: undefined,
      filterInfo: filterInfo.value,
    });
  }
  let surveyIndex = index ?? surveyHistories.value.length - 1;

  // surveyを実施した対象の箇所までスクロールする
  await nextTick();
  const element = document.querySelector(`.survey-history-${surveyIndex}`);
  if (element) element.scrollIntoView({ behavior: 'smooth', block: 'center' });

  try {
    for await (let chunk of api.postSurvey(
      sessionId,
      question.value,
      filterInfo.value.searchScope,
      filterInfo.value.docTypes,
      cached_expanded_queries.value,
      store,
      filterInfo.value.specifiedFiles,
    )) {
      if (chunk === undefined) continue;
      handleChunk(chunk, surveyIndex);
    }

    // ファイル指定履歴を追加
    if (filterInfo.value.specifiedFiles.length > 0) {
      filterInfo.value.specifiedFiles.forEach(file => {
        createSpecifiedFileHistories(file.id);
      });
    }
  } catch (e: unknown) {
    updateSurveyHistory(surveyIndex, {
      isError: true,
      errorCause: ((e as Error).cause as string) ?? undefined,
    });
    // 意図的に発生させたエラー以外は握り潰さないようにする
    if (((e as Error).cause as string) !== 'no_user_marks')
      throw new Error(`要約生成に失敗しました: ${e as string}`);
  } finally {
    isClosingAllDocuments.value = false;

    emitter.emit('update-survey-session', {
      session_id: sessionId,
      title: '',
      updated_at: new Date().toISOString(),
    });

    // 計測
    const sourcesForTracking = sources.value.map((source, idx) => {
      return {
        document: {
          type: source.doc_type,
          id: source.id,
        },
        rank: idx + 1,
      };
    });
    const surveyExecutionFrom =
      (route.query.from as SurveyExecutionFrom) ?? 'survey_page';
    await trackingSurveyEvent(SURVEY_PAGE_NAME, {
      query: null,
      from: surveyExecutionFrom,
      search_scope: filterInfo.value.searchScope,
      data_sources: docTypesToUse.value,
      data_sources_filter_used: filterInfo.value.docTypes.length > 0,
      contents: sourcesForTracking,
      result_count: sourcesForTracking.length,
      session_id: sessionId,
    });
  }
};

/**
 * スレッドに紐づくsurvey_historiesのデータを取得
 *
 * 2024-11-14現在 スレッド内のデータ上限は50件のため、ページング処理は不要 (考慮事項が増えるので、必要になるまで実装しない)
 * ※ APIとしてはページング処理ができるようになっている
 */
const fetchSurveyHistories = async () => {
  // 初期化
  initializeSurveyState();

  isFetchingHistories.value = true;
  const PAGE_NUMBER = 1;

  const data = await api.fetchSurveyHistories(
    surveySessionId.value,
    PAGE_NUMBER,
    SESSION_LIMIT_COUNT,
    'asc',
  );

  const additionalSurveyHistories = await Promise.all(
    data.map(async history => {
      let summary: Part[] = [];
      const isNoHitSources =
        history.sources.length === 0 && !history.model_response;
      if (!isNoHitSources) {
        const markdownToHtml = marked.parse(history.model_response) as string;
        const sanitizedHtml = sanitize(markdownToHtml, MarkdownToHtmlOptions);
        const parser = new DOMParser();
        const doc = parser.parseFromString(sanitizedHtml, 'text/html');
        const { processedParts } = processNode(doc.body, 0, 1, history.sources);
        summary = processedParts;
      }

      return {
        requestId: history.request_id,
        question: history.question,
        sources: history.sources,
        originMarkdownSummary: history.model_response ?? '',
        summary, // パーサーを通した後の値を設定
        isNoHitSources,
        isError: false,
        isInProgressSurvey: false,
        enableAnimation: false,
        feedbackType: history.active_feedback?.evaluation,
        filterInfo: convertKeysToCamelCase(history.filter_info, false),
      };
    }),
  );
  surveyHistories.value = additionalSurveyHistories;
  if (data.length > 0)
    relatedQuestions.value = data[data.length - 1].related_questions;

  isLoaded.value = true;
  isFetchingHistories.value = false;
  await nextTick();

  // 画面最下部にスクロール
  const element = document.documentElement;
  const bottom = element.scrollHeight - element.clientHeight;
  window.scroll(0, bottom);
};

/**
 * 新規でスレッドを作成した場合の、初回のsurvey自動実行
 */
const initializeSurveyFromQuery = async (query: Record<string, unknown>) => {
  if (
    surveyHistories.value.length === 0 &&
    !isSurveyInProgress.value &&
    query.question !== undefined
  ) {
    question.value = query.question as string;
    filterInfo.value.searchScope = query.searchScope as SearchScope;
    filterInfo.value.docTypes = getDocTypesQueryParam(
      query.docTypes as LocationQueryValue | Array<LocationQueryValue>,
    );
    filterInfo.value.specifiedFiles = getSpecifiedFilesQueryParam(
      query.specifiedFiles as LocationQueryValue | Array<LocationQueryValue>,
    );

    if (question.value) await submitSurveyStream(true);
    await router.replace({ query: {} });
  }
};

provide(closeAllDocumentsKey, isClosingAllDocuments);

onMounted(async () => {
  await fetchSurveyHistories();
  await initializeSurveyFromQuery(route.query);
});

// NOTE: 現状のAnewsの作りではパスが同じでパラメータだけ違う場合に、再レンダリングが発生しないため、自力で検知して初期表示処理を行う
// 例) 別の履歴を選択して要約結果を表示するようなパターンで、`/survey/xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx` から `/survey/yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy` に遷移した時
onBeforeRouteUpdate(async (to, from, next) => {
  if (to.params.survey_session_id !== from.params.survey_session_id) {
    next();

    surveyHistories.value = [];
    surveySessionId.value = to.params.survey_session_id as string;
    await fetchSurveyHistories();
    await initializeSurveyFromQuery(to.query);
  } else {
    next(); // 初回実行後にクエリパラメータを消す処理の時に必要
  }
});
</script>

<template>
  <div class="survey-session-container">
    <Header
      v-if="!isFetchingHistories"
      title="要約結果"
      header-width="var(--survey-session-width)"
      :is-one-line="true"
    />
    <div class="content-wrapper">
      <div
        class="content"
        :class="`survey-history-${index}`"
        v-for="(history, index) in surveyHistories"
        :key="index"
      >
        <SurveyHistory
          :request-id="history.requestId"
          :question="history.question"
          :sources="history.sources"
          :origin-markdown-summary="history.originMarkdownSummary"
          :summary="history.summary"
          :is-no-hit-sources="history.isNoHitSources"
          :is-error="history.isError"
          :error-cause="history.errorCause"
          :session-id="surveySessionId"
          :is-in-progress-survey="history.isInProgressSurvey"
          :enable-animation="history.enableAnimation"
          :feedback-type="history.feedbackType"
          :filter-info="history.filterInfo"
          @update-document="
            ({ sourceIndex, document }) =>
              updateDocument(index, sourceIndex, document)
          "
        />
      </div>
      <SurveyRelatedQuestions
        v-if="
          relatedQuestions.length > 0 &&
          !isSurveyInProgress &&
          !isFetchingHistories
        "
        :related-questions="relatedQuestions"
        @select-question="startSurveyFromRelatedQuestion"
      />

      <div class="input-wrapper">
        <SurveyInput
          :key="surveySessionId"
          v-if="surveyHistories.length < SESSION_LIMIT_COUNT"
          :enable-transform-input="true"
          :is-loading="isSurveyInProgress"
          :initial-search-scope="latestSurveyHistory?.filterInfo.searchScope"
          :initial-doc-types="latestSurveyHistory?.filterInfo.docTypes"
          :initial-specified-files="
            latestSurveyHistory?.filterInfo.specifiedFiles
          "
          @submit="startSurvey"
        />
        <SurveySessionSwitcherInput v-else />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.survey-session-container {
  width: 100%;
  margin: -24px 0 0 0;

  .content-wrapper {
    max-width: var(--survey-session-width);
    margin: 0 auto;
    margin-bottom: 72px; // 入力欄 (survey-input.vue) にフォーカスが当たっている時に、下の方の文字が見えないので余白をとる
    padding: 0 32px;
  }

  .content {
    margin: 40px 0;
  }

  // propsのtabsに値を渡さないと強制的に`position: sticky`が適用されるため、スタイルを上書き
  :deep(.no-tab) {
    position: static;
  }
}
</style>
