<script setup lang="ts">
import { DgrIcon } from '@stockmarkteam/donguri-ui';
import Divider from '@/components/common/divider.vue';

interface Props {
  relatedQuestions: Array<string>;
}
const emit = defineEmits<{
  'select-question': [selectedQuestion: string];
}>();
const props = defineProps<Props>();
</script>
<template>
  <div class="survey-related-questions-container">
    <div class="survey-related-questions-header">関連質問</div>
    <div class="spacing-08" />
    <div class="survey-related-questions-content">
      <div v-for="(question, i) in props.relatedQuestions" :key="i">
        <Divider />
        <div class="survey-related-questions-item">
          <div>
            {{ question }}
          </div>
          <button
            class="c-btn c-btnOutline small"
            @click="emit('select-question', question)"
          >
            <DgrIcon
              size="small"
              :keep-fill="false"
              name="sparkles-fill"
              class="ai-icon"
            />
            生成
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.survey-related-questions-container {
  background-color: $color-white;
  padding: 16px 16px 0 16px;
  border-radius: 4px;
  border: solid 1px $color-gray400;
  .survey-related-questions-header {
    font-size: 14px;
  }
  .survey-related-questions-content {
    padding: 8px 0;
    .survey-related-questions-item {
      padding: 16px 0;
      display: flex;
      gap: 10px;
      justify-content: space-between;
      align-items: center;
      button {
        width: 78px;
        white-space: nowrap;
        font-size: 12px;
        padding: 5px 16px;
        .ai-icon {
          margin-right: 4px;
        }
      }
    }
  }
}
</style>
