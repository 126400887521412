<script setup lang="ts">
import { computed, defineEmits, defineProps } from 'vue';
import { useRoute } from 'vue-router';
import { DgrIcon } from '@stockmarkteam/donguri-ui';
import { FileNameSearchResponse } from '@/types';
import {
  getFileTypeIconName,
  getStorageType,
} from '@/utils/user-document/common';
import { isDev } from '@/featureFlags';

interface Props {
  file: FileNameSearchResponse;
}
const props = defineProps<Props>();
const emit = defineEmits<{
  (e: 'select-file', file: FileNameSearchResponse): void;
}>();
const route = useRoute();

/**
 * デバッグ用の判定項目
 *
 * dev環境かつ、クエリパラメータに該当の項目がある場合に、
 * ストレージ名 (box, share_point, Anewsストレージ) を表示する
 */
const showStorageType = computed(() => {
  if (!isDev) return false;

  return route.query?.showStorageType === 'true';
});
const handleSelectFile = () => {
  emit('select-file', props.file);
};
</script>

<template>
  <div
    @keydown.enter="handleSelectFile"
    @click="handleSelectFile"
    class="file"
    role="button"
    tabindex="0"
  >
    <DgrIcon :name="getFileTypeIconName(file.file_type)" size="default" />
    <div>
      <span>{{ file.filename }}</span>
      <div class="update-info c-text c-text--s">
        <span>更新日: {{ file.modified_at }}</span>
        <span>更新者: {{ file.last_updated_by }}</span>
        <span v-if="showStorageType"
          >ストレージ: {{ getStorageType(file.url) }}</span
        >
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.file {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 10px;
  padding: 8px;
  cursor: pointer;

  &:hover {
    background-color: $color-gray200;
  }
}

.update-info {
  margin-top: 4px;
  display: flex;
  gap: 10px;
  color: $color-gray800;
}
</style>
