<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import api from '@/api';
import { ContentsContext } from '@/api/tracking';
import noImage1to1 from '@/assets/noimage-1to1.jpg';
import { DOC_TYPE_LABELS } from '@/constants';
import { DgrIcon } from '@stockmarkteam/donguri-ui';
import DocumentSentenceDisplay from '@/components/common/adp-document/document-sentence-display.vue';
import UserDocumentOpenPreviewModalButton from '@/components/user-document/user-document-open-preview-modal-button.vue';
import {
  UserDocument,
  UserDocumentEventName,
  UserDocumentFeatureName,
  UserDocumentViewInfo,
  UserDocumentViewType,
} from '@/types';
import { useUserDocumentActionHistories } from '@/utils/composables/useUserDocumentActionHistories';
import { imageErrorHandler } from '@/utils/errorHandler';
import {
  getFileTypeIconName,
  getPageUrl,
  getStorageType,
} from '@/utils/user-document/common';
import { getIsPageNumberAvailable } from '@/utils/user-document/common';
import { userSession } from '@/utils/userSession';
import { isDev } from '@/featureFlags';

interface Props {
  userDocument: UserDocument;
  badgeNumber: number;
  /* これより下は計測で使用 */
  pageName: UserDocumentFeatureName;
  feature: UserDocumentFeatureName;
  rankInWholeFeed: number;
  contentsContext: Extract<ContentsContext, { event_name: 'survey' }>;
}

const props = defineProps<Props>();
const route = useRoute();
const { createUserDocumentViewHistories } = useUserDocumentActionHistories(
  userSession.getUserId(),
);

const imageUrl = computed(() => props.userDocument.image_url || noImage1to1);
const isPageNumberAvailable = computed(() =>
  getIsPageNumberAvailable(props.userDocument),
);
const documentUrl = computed(() => {
  if (!isPageNumberAvailable.value) return props.userDocument.url;
  const pageNumber = props.userDocument.page_number;
  return getPageUrl(pageNumber ?? 1, props.userDocument);
});

const folderName = computed(() => {
  const path = props.userDocument.path;
  return path.split('/').slice(-1)[0];
});

const highlightKeywords = computed(() => {
  const hit_keywords = props.userDocument.hit_keywords ?? [];
  const synonym_keywords = props.userDocument.synonym_keywords ?? [];
  return [...hit_keywords, ...synonym_keywords]
    .filter(keyword => keyword.length > 0)
    .sort(keyword => -keyword.length);
});

/**
 * デバッグ用の判定項目
 *
 * dev環境かつ、クエリパラメータに該当の項目がある場合に、
 * ストレージ名 (box, share_point, Anewsストレージ) を表示する
 */
const showStorageType = computed(() => {
  if (!isDev) return false;

  return route.query?.showStorageType === 'true';
});

const clickUserDocumentTitle = async () => {
  await createUserDocumentViewHistories(props.userDocument.id);
  await api.trackingUserDocumentEvent(
    'user_document_view',
    userDocumentViewInfo('original'),
  );
};

const trackingUserDocumentEvent = async (eventName: UserDocumentEventName) =>
  await api.trackingUserDocumentEvent(eventName, {
    page: { name: props.pageName },
    feature: props.feature,
    rank: props.rankInWholeFeed,
    user_document: { id: props.userDocument.id },
    contents_context: props.contentsContext,
    view_type: 'original',
  } as UserDocumentViewInfo);

const userDocumentViewInfo = (
  viewType: UserDocumentViewType,
): UserDocumentViewInfo => ({
  page: {
    name: props.pageName,
  },
  feature: props.feature,
  rank: props.rankInWholeFeed,
  user_document: { id: props.userDocument.id },
  contents_context: props.contentsContext,
  view_type: viewType,
});
</script>

<template>
  <div class="user-document-card">
    <div class="user-document-data-source-info c-text c-text--xs">
      <div class="doc-type-label c-title c-title--xs">
        {{ badgeNumber }}.{{ DOC_TYPE_LABELS[userDocument.doc_type] }}
      </div>
      <div class="user-document-page-number">
        <div>
          <DgrIcon :name="getFileTypeIconName(userDocument.file_type)" />
        </div>
        <div class="spacing-02"></div>
        <template v-if="isPageNumberAvailable">
          <div class="page-number">ページ：{{ userDocument.page_number }}</div>
        </template>
      </div>
      <component
        :is="userDocument.folder_url ? 'a' : 'span'"
        :href="userDocument.folder_url"
        target="_blank"
        rel="noopener noreferrer"
        class="user-document-data-source-name"
        @click="trackingUserDocumentEvent('user_document_folder_view')"
      >
        <DgrIcon :keep-fill="false" name="folder" />
        <div class="spacing-04"></div>
        <div class="user-document-data-source-name-text">
          {{ folderName }}
        </div>
      </component>
    </div>
    <div class="spacing-04"></div>
    <div class="user-document-content-wrapper">
      <div class="content">
        <div class="user-document-title c-title c-title--xm">
          <a
            :href="documentUrl"
            target="_blank"
            rel="noopener noreferrer"
            @click="clickUserDocumentTitle()"
          >
            {{ userDocument.title }}
          </a>
        </div>
        <div class="spacing-04"></div>
        <DocumentSentenceDisplay
          :text="userDocument.chunk_text"
          :highlight-keywords="highlightKeywords"
          :line-clamp="2"
        ></DocumentSentenceDisplay>
      </div>
      <div>
        <a
          :href="documentUrl"
          target="_blank"
          rel="noopener noreferrer"
          @click="clickUserDocumentTitle()"
        >
          <img
            class="user-document-thumbnail"
            loading="lazy"
            :src="imageUrl"
            :data-src="imageUrl"
            @error="imageErrorHandler($event, '3to1')"
          />
        </a>
      </div>
    </div>
    <div class="spacing-04"></div>
    <div class="c-text c-text--s debug-info" v-if="showStorageType">
      ストレージ: {{ getStorageType(userDocument.url) }}
    </div>
    <div class="user-document-bottom-buttons">
      <UserDocumentOpenPreviewModalButton
        :id="userDocument.id"
        :title="userDocument.title"
        :target-page="userDocument.page_number"
        :file-total-pages="userDocument.file_total_pages"
        :user-document-view-info="userDocumentViewInfo('preview')"
        :preview-image-path-template="userDocument.preview_image_path_template"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.user-document-card {
  width: 100%;
  background-color: white;
  padding: 16px 16px 4px;
  column-gap: 16px;
  box-sizing: border-box;
  border: 1px solid $color-gray400;
  border-radius: 4px;
  overflow: hidden;
  max-width: 308px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .user-document-left {
    flex: 1;
    min-width: 0; // 子要素の幅が親要素の幅を超えないようにする
  }
  .doc-type-label {
    background-color: $color-gray1000;
    color: $color-white;
    padding: 4px;
    margin-right: 6px;
    border-radius: 2px;
    font-weight: 700;
    text-wrap: nowrap;
  }

  .user-document-thumbnail {
    max-width: 48px;
    object-fit: contain;
    font-weight: 400;
  }
  .user-document-data-source-info {
    padding: 4px 0;
    color: $color-gray800;
    font-weight: 500;
    display: flex;
    align-items: center;
    .user-document-page-number {
      display: flex;
      align-items: center;
      margin-right: 4px;
      .page-number {
        white-space: nowrap;
        border-right: 1px solid $color-gray400;
        padding-right: 10px;
      }
    }
    .user-document-data-source-name {
      display: flex;
      align-items: center;
      fill: $color-gray600;
      min-width: 0;
      .user-document-data-source-name-text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      &a:hover {
        .user-document-data-source-name-text {
          text-decoration: underline;
        }
      }
    }
  }
  .user-document-title {
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    word-break: break-all;
    font-weight: 500;
    a:hover {
      text-decoration: underline;
    }
  }
  .user-document-content-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    .content {
      flex: 1;
    }
  }

  .debug-info {
    color: $color-gray800;
    margin-bottom: 4px;
  }

  .user-document-bottom-buttons {
    margin-top: auto;
  }
}
</style>
