import { PAGE_NUMBER_PLACEHOLDER } from '@/constants';
import {
  FileNameSearchResponse,
  FileType,
  StorageType,
  UserDocumentEntity,
} from '@/types';
import { formatDate } from '@/utils/formatters';

export const getPageUrl = (
  page: number,
  doc: UserDocumentEntity | FileNameSearchResponse,
) => {
  const id = doc.id;
  const url = doc.url;
  const storageType = getStorageType(url);

  switch (storageType) {
    case 'box':
      return `${url}#p=${page}`;
    case 'share_point':
      return `${url}#page=${page}`;
    case 'anews_storage':
      return `${window.location.origin}${window.location.pathname}#/user_documents/${id}?page=${page}`;
    default:
      return url;
  }
};

export const getIsPageNumberAvailable = (
  doc: UserDocumentEntity | FileNameSearchResponse,
) => {
  return doc.file_type !== 'Word' || doc.url === '';
};

export const getFileTypeIconName = (fileType: FileType) => {
  switch (fileType) {
    case 'PDF':
      return 'file-pdf-fill-color';
    case 'Word':
      return 'file-word-fill-color';
    case 'PowerPoint':
      return 'file-powerpoint-fill-color';
    default:
      return 'file-v';
  }
};

export const getFolderName = (doc: UserDocumentEntity) => {
  const path = doc.path;
  return path.split('/').slice(-1)[0];
};

export const getDocumentUrl = (
  doc: UserDocumentEntity | FileNameSearchResponse,
) => {
  if (!getIsPageNumberAvailable(doc)) return doc.url;
  const pageNumber = 'page_number' in doc ? doc.page_number : undefined;
  return getPageUrl(pageNumber ?? 1, doc);
};

export const getModifiedAt = (doc: UserDocumentEntity) => {
  return doc.modified_at ? formatDate(doc.modified_at) : undefined;
};

export const getPreviewImageUrls = (
  file_total_pages: number | undefined,
  preview_image_path_template: string,
) => {
  return [...Array(file_total_pages ?? 0)].map((_, i) =>
    preview_image_path_template.replace(
      PAGE_NUMBER_PLACEHOLDER,
      (i + 1).toString(),
    ),
  );
};

export const getStorageType = (url: string): StorageType | 'unknown' => {
  if (url.includes('box.com')) return 'box';
  else if (url.includes('sharepoint.com')) return 'share_point';
  else if (url === '') return 'anews_storage';
  return 'unknown';
};
