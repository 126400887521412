<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import api from '@/api';
import { ContentsContext } from '@/api/tracking';
import { DgrIcon } from '@stockmarkteam/donguri-ui';
import { DgrSelectbox } from '@stockmarkteam/donguri-ui';
import PromoModal from '@/components/search/promotion-user-document-trial/promo-modal.vue';
import SearchHeader from '@/components/search/search-header.vue';
import UserDocumentCard from '@/components/user-document/user-document-card.vue';
import { DocType } from '@/types';
import { useStore } from '@/utils/vue';

const ES_MAX_RESULT_WINDOW = 10000; // Elastic Searchの結果ウィンドウサイズの上限値
const DUMMY_USER_DOCUMENT_COUNT = 100; // ダミーの社内情報ドキュメント件数

const store = useStore();
const route = useRoute();

const searchQuery = ref<string | undefined>(route.query.query as string);
/* SearchHeader関連 */
const teamInfo = computed(() => store.state.teamInfo.teamInfo);
const enableTechnicalLiterature = computed(
  () => teamInfo.value?.enable_technical_literature ?? false,
);
/**
 * 公開情報の総件数取得APIで使用
 *
 * NOTE: computedで定義しているdocTypesを使用すると、社内情報の際に'user_document'になってしまうため、個別で定義する
 */
const openDocumentsDocTypes = computed<DocType[]>(() => {
  return enableTechnicalLiterature.value
    ? ['article', 'research_paper', 'patent', 'report']
    : ['article', 'report'];
});
const resultCount = computed(() => store.state.searchResults.resultCount);
const openDocumentsCount = ref<undefined | number>(undefined);
const displayOpenDocumentsCount = computed(() => {
  if (openDocumentsCount.value === undefined) return undefined;
  return openDocumentsCount.value > ES_MAX_RESULT_WINDOW
    ? ES_MAX_RESULT_WINDOW
    : openDocumentsCount.value;
});
/* コンテンツ領域関連 */
const resultUserDocuments = computed(
  () => store.state.searchResults.resultUserDocuments,
);
const contentsContext: ContentsContext = {
  event_name: 'user_document_search',
  session_id: undefined,
};
const resultOrderProps = computed(() => [
  { value: 'recommended_v2', label: 'おすすめ順' },
  { value: 'popular', label: '人気順' },
  { value: 'latest', label: '最新順' },
]);
const resultOrderValue = ref('recommended_v2');

onMounted(async () => {
  // main-view.vueでoverflowなどスクロールに関する制御をしておらず、当コンポーネントで`overflow: hidden
  // などで制御できないため強制的に全体のスクロールを無効化 (このコンポーネント表示中はスクロールが必要な箇所がないため問題なし)
  document.body.style.overflow = 'hidden';
  await store.dispatch('searchResults/userDocumentSearchDemo');
  openDocumentsCount.value = await getOpenDocumentsCount();
  await onPromoView();
});

onBeforeUnmount(async () => {
  document.body.style.overflow = '';
  store.commit('searchResults/resetCondition');
  await store.dispatch('searchResults/reset');
});

watch(route.query, async () => {
  searchQuery.value = route.query.query as string;
  openDocumentsCount.value = await getOpenDocumentsCount();
});

const getOpenDocumentsCount = async () => {
  if (searchQuery.value === undefined) return undefined;
  try {
    const count = await api.allOpenDocumentsCount(
      searchQuery.value,
      openDocumentsDocTypes.value,
    );
    return count;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return undefined;
  }
};

// viewの計測
const onPromoView = async () => {
  await api.trackingUserDocumentsDemoEvent('user_document_contact_view');
};

/* 問い合わせページに遷移したユーザーの計測 */
const onPromoClick = async () => {
  await api.trackingUserDocumentsDemoEvent('user_document_contact_click', {
    page: {
      name: 'user_document_search',
    },
    feature: 'user_document_search',
  });
};
</script>

<template>
  <div class="o-search-result">
    <SearchHeader
      :open-documents-count="displayOpenDocumentsCount"
      :user-documents-count="DUMMY_USER_DOCUMENT_COUNT"
      :is-enable-technical-literature="enableTechnicalLiterature"
      :is-button-disabled="resultCount === 0"
    ></SearchHeader>
    <PromoModal>
      <template #body>
        <div class="header">
          <img
            src="@/assets/user-documents-image.png"
            alt="社内情報検索機能のイメージ画像"
          />
        </div>
        <div class="content">
          <div>
            <p>
              これまでの調査レポートや技術報告書、実験データなど、組織に眠る様々な情報を、ビジネスニュースや論文・特許などの外部情報と同時に検索できる機能です。
            </p>
            <p>
              社内外の情報を横断的に検索することが出来るようになる他、ストックマークのAI技術を用いることで「検索結果の全体像が一目で分かる要約生成」や、「ファイル内全文を対象に、検索意図に沿った該当箇所のピンポイント表示」が可能になります。
            </p>
          </div>
        </div>
        <div class="button-area">
          <a
            href="https://stockmark.co.jp/product/anews/documents/internal-search"
            target="_blank"
            rel="noopener noreferrer"
            @click="onPromoClick()"
          >
            <button class="c-btn c-btn--AnewsPrimary enquiries-button">
              トライアルのお問い合わせはこちら
            </button>
          </a>
        </div>
      </template>
    </PromoModal>
    <div class="search-results-container">
      <div class="moving-search-summary c-text--m">
        <div class="description">
          <DgrIcon
            name="sparkles-fill"
            :keep-fill="false"
            class="gray-sparkles"
          />
          <p class="survey-link" role="button">
            「社内情報検索機能」の要約を生成する
          </p>
        </div>
        <button class="c-btn--AnewsPrimary generation-button">
          <DgrIcon
            name="sparkles-fill"
            size="small"
            :keep-fill="false"
            class="white-sparkles"
          />
          <span class="spacing-04"></span>
          生成
        </button>
      </div>
      <div class="results">
        <div class="spacing-16"></div>
        <div>
          <div class="results-count-and-select-box">
            <div class="c-title c-title--m">検索結果</div>
            <div class="results-count-text">
              <span>・</span>
              <span id="search-result-count"
                >{{ DUMMY_USER_DOCUMENT_COUNT }}件</span
              >
            </div>
            <div class="spacing-16"></div>
            <DgrSelectbox
              :options="resultOrderProps"
              :model-value="resultOrderValue"
              size="small"
            ></DgrSelectbox>
          </div>
        </div>
        <div class="o-results" v-if="resultUserDocuments.length > 0">
          <div class="spacing-08"></div>
          <div class="spacing-02"></div>
          <div class="user-document-card-list">
            <template
              v-for="(userDocument, i) in resultUserDocuments"
              :key="userDocument.id"
            >
              <!-- UserDocumentCardは元からバッジを表示する際の遅延 (:badge-animation-delay)がないので即時表示しかできないようになっている -->
              <UserDocumentCard
                :user-document="userDocument"
                :rank-in-whole-feed="i + 1"
                :contents-context="contentsContext"
              />
            </template>
          </div>
          <div class="o-no-more-results c-text c-text--m">
            これ以上、表示できる情報はありません
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.o-search-result {
  width: 100%;
  margin: -24px 0 0 0;
  overflow: hidden;
}

.search-results-container {
  display: flex;
  flex-direction: column;
  padding: 0 32px;
  margin: 0;
  max-width: 970px;
  .o-results {
    width: auto;
  }
  box-sizing: border-box;
  .moving-search-summary {
    margin-top: 10px;
    display: grid;
    grid-template-columns: 10fr 1fr;
    justify-content: space-between;
    padding: 16px;
    background-color: $color-white;
    border-radius: 4px;
    align-items: center;

    .description {
      display: flex;
      align-items: center;

      .survey-link {
        cursor: pointer;
        margin-left: 10px;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    .generation-button {
      width: 80px;
      height: 40px;
      padding: 8px 16px;
      margin-left: 16px;
      cursor: pointer;
      justify-self: end;
    }

    .gray-sparkles {
      flex-shrink: 0;
      fill: $color-gray1000;
    }
    .white-sparkles {
      fill: $color-white;
    }
  }
}
.results {
  width: 'auto';
  .results-count-and-select-box {
    display: flex;
    justify-content: start;
    align-items: center;
    .results-count-text {
      font-size: 12px;
      font-weight: 400;
      color: $color-gray600;
    }
    #search-result-count {
      min-width: 66px;
      display: inline-block;
      text-align: end;
    }
  }
  .user-document-card-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  .o-no-more-results {
    margin: 16px 0;
    color: #b3b3b3;
    text-align: center;
  }
}

img {
  width: 100%;
  max-width: 480px;
  max-height: 258px;
  height: auto;
  display: block;
  margin: 0 auto;
}

.content {
  font-size: 14px;
  margin: 16px 0 24px;
}

.button-area {
  display: flex;
  justify-content: center;
  font-size: 14px;
  .enquiries-button {
    display: inline-block;
    width: auto;
  }
}

@media (min-width: 800px) and (max-height: 790px) {
  img {
    max-width: 360px;
    margin: 0 auto;
  }
}

@media (max-width: 800px) {
  img {
    max-width: 280px;
    margin: 0 auto;
  }

  .content {
    font-size: 12px;
    margin: 8px 0 12px;
  }

  .button-area {
    font-size: 12px;
  }
}
</style>
