<script setup lang="ts">
import { computed, onMounted, onUnmounted, reactive, toRefs } from 'vue';
import BasicEmptyFeed from '@/components/common/basic-empty-feed.vue';
import TechnicalLiteratureList from '@/components/common/technical-literature/technical-literature-list.vue';
import {
  FeedType,
  Theme,
  ThemeFeedPatent,
  ThemeFeedResearchPaper,
} from '@/types';
import { formatDayOfWeek } from '@/utils/formatters';
import { STATES } from '@/utils/swr';
import { useEmitter } from '@/utils/vue';
import { featureFlags } from '@/featureFlags';

type TechnicalLiteratureDocType = 'research_paper' | 'patent';
type TechnicalLiterature = ThemeFeedResearchPaper | ThemeFeedPatent;

type Props = {
  feed: 'theme' | 'personal';
  docType: TechnicalLiteratureDocType;
  feedType: FeedType;
  feedDate?: string;
  domesticDocuments?: {
    items: (ThemeFeedResearchPaper | ThemeFeedPatent)[];
    state: keyof typeof STATES | 'idle';
  };
  foreignDocuments?: {
    items: (ThemeFeedResearchPaper | ThemeFeedPatent)[];
    state: keyof typeof STATES | 'idle';
  };
  theme?: Theme;
  enableToEditTheme?: boolean;
  keywordFeedType?: 'keyword_feed' | 'keyword_feed_from_search';
  toggleKeywordFeedType?: () => void;
};
const props = withDefaults(defineProps<Props>(), {
  domesticDocuments: () => ({
    items: [] as (ThemeFeedResearchPaper | ThemeFeedPatent)[],
    state: STATES.SUCCESS,
  }),
  foreignDocuments: () => ({
    items: [] as (ThemeFeedResearchPaper | ThemeFeedPatent)[],
    state: STATES.SUCCESS,
  }),
});

const { domesticDocuments, foreignDocuments, feedDate } = toRefs(props);
const emitter = useEmitter();

const formattedFeedDate = formatDayOfWeek(feedDate?.value ?? '');

const isSameDocument = (a: TechnicalLiterature, b: TechnicalLiterature) =>
  a.id === b.id && a.doc_type === b.doc_type;

const updateDocument = (
  documents: TechnicalLiterature[],
  updatedDocument: TechnicalLiterature,
) => {
  const index = documents.findIndex(a => {
    return isSameDocument(a, updatedDocument);
  });
  if (index >= 0) {
    documents[index].marks = updatedDocument.marks;
  }
  return documents;
};

const handleDocumentUpdate = (document: TechnicalLiterature) => {
  const documents =
    document.lang == 'en' ? foreignDocuments : domesticDocuments;
  updateDocument(documents.value.items, document);
};

const removedDocumentIds = reactive({
  research_paper: [],
  patent: [],
} as { [K in TechnicalLiteratureDocType]: number[] });
const removeDocument = ({ id, doc_type }: TechnicalLiterature) => {
  removedDocumentIds[doc_type].push(id);
};
const restoreDocument = ({ id, doc_type }: TechnicalLiterature) => {
  const removed = [...removedDocumentIds[doc_type]];
  if (removed.includes(id)) {
    removedDocumentIds[doc_type] = removed.filter(a => a !== id);
  }
};

onMounted(async () => {
  emitter.on('article-updated', handleDocumentUpdate);
  emitter.on('personal-feed-article-removed', removeDocument);
  emitter.on('personal-feed-article-restored', restoreDocument);
});

onUnmounted(() => {
  emitter.off('article-updated', handleDocumentUpdate);
  emitter.off('personal-feed-article-removed', removeDocument);
  emitter.off('personal-feed-article-restored', restoreDocument);
});

const hasPastTechnicalLiterature = computed(
  () =>
    [...domesticDocuments.value.items, ...foreignDocuments.value.items].filter(
      p => !p.is_new,
    ).length > 0,
);

const isKeywordFeedFromSearchEnabled =
  featureFlags.ANDEV_5508_KEYWORD_FEED_FROM_SEARCH;
</script>

<template>
  <div
    class="technical-literature-feed"
    :key="`${docType}-${feedType}-${domesticDocuments.state}-${foreignDocuments.state}`"
  >
    <div class="title">
      <span class="c-title c-title--xm"
        >今週の{{ docType === 'patent' ? '特許' : '論文' }}
        <span
          v-if="isKeywordFeedFromSearchEnabled && toggleKeywordFeedType"
          class="feed-toggle c-text c-text--s"
          @click="toggleKeywordFeedType"
          >{{
            keywordFeedType === 'keyword_feed' ? '検索結果' : 'テーマフィード'
          }}に切り替え</span
        >
      </span>
      <span class="subtitle c-text c-text--m"
        >{{ feed === 'personal' ? 'パーソナル' : 'テーマ'
        }}{{
          docType === 'patent' ? '特許' : '論文'
        }}は毎週日曜日に更新されます(朝7時)</span
      >
    </div>
    <template v-if="feedType === 'foreign' && docType === 'patent'">
      <BasicEmptyFeed>海外特許の配信は行っておりません。</BasicEmptyFeed>
    </template>
    <template
      v-else-if="
        (['all', 'domestic'].includes(feedType) &&
          domesticDocuments.state === STATES.SUCCESS) ||
        (['all', 'foreign'].includes(feedType) &&
          foreignDocuments.state === STATES.SUCCESS)
      "
    >
      <div class="new-tech-lit">
        <TechnicalLiteratureList
          :doc-type="docType"
          :feed-type="feedType"
          :formatted-feed-date="formattedFeedDate"
          :domestic-documents="
            domesticDocuments.items.filter(
              p => p.is_new && !removedDocumentIds[p.doc_type].includes(p.id),
            )
          "
          :foreign-documents="
            foreignDocuments.items.filter(
              p => p.is_new && !removedDocumentIds[p.doc_type].includes(p.id),
            )
          "
          :theme="theme"
          :enable-to-edit-theme="enableToEditTheme"
        />
      </div>
      <div v-if="hasPastTechnicalLiterature" class="old-tech-lit">
        <div class="past-news-title c-title c-title--xm">
          過去の{{ docType === 'patent' ? '特許' : '論文' }}
        </div>
        <TechnicalLiteratureList
          :doc-type="docType"
          :feed-type="feedType"
          :domestic-documents="
            domesticDocuments.items.filter(
              p => !p.is_new && !removedDocumentIds[p.doc_type].includes(p.id),
            )
          "
          :foreign-documents="
            foreignDocuments.items.filter(
              p => !p.is_new && !removedDocumentIds[p.doc_type].includes(p.id),
            )
          "
          :theme="theme"
          :enable-to-edit-theme="enableToEditTheme"
        />
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.technical-literature-feed {
  display: flex;
  flex-direction: column;
  width: 540px;

  .title {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-bottom: 16px;

    .subtitle {
      color: #b3b3b3;
    }

    .feed-toggle {
      align-self: center;
      padding: 0 18px;
      cursor: pointer;
      color: #1da482;
    }
  }

  .past-news-title {
    margin-top: 40px;
    margin-bottom: 24px;
  }
}
</style>
