<script setup lang="ts">
import { computed, ref } from 'vue';
import api from '@/api';
import { ContentsContext } from '@/api/tracking';
import {
  AdpDocument,
  isAdpDocument,
  isDocument,
  UserDocument,
  UserDocumentViewInfo,
} from '@/types';
import { isUserDocument } from '@/types';
import { getPublisher } from '@/utils/adpDocument';
import { useCurrentRoutePath } from '@/utils/composables/useCurrentRoutePath';
import { useUserDocumentActionHistories } from '@/utils/composables/useUserDocumentActionHistories';
import { SURVEY_PAGE_NAME } from '@/utils/survey/constants';
import { getPageUrl } from '@/utils/user-document/common';
import { userSession } from '@/utils/userSession';
import { useEmitter } from '@/utils/vue';

interface Props {
  number: number;
  sourceDocument: AdpDocument | UserDocument;
  contentsContext?: ContentsContext;
}

const props = defineProps<Props>();

const emitter = useEmitter();
const { getCurrentRoutePath } = useCurrentRoutePath();
const { createUserDocumentViewHistories } = useUserDocumentActionHistories(
  userSession.getUserId(),
);

const badgeElement = ref(undefined as HTMLDivElement | undefined);
const documentPublisher = computed(() => {
  if (isUserDocument(props.sourceDocument)) {
    return props.sourceDocument.last_updated_by;
  }
  return getPublisher(props.sourceDocument);
});

const documentUrl = computed(() => {
  if (isUserDocument(props.sourceDocument) && isPageNumberAvailable.value) {
    const pageNumber = props.sourceDocument.page_number;
    return getPageUrl(pageNumber ?? 1, props.sourceDocument);
  }

  return props.sourceDocument.url;
});

const handleClick = async () => {
  const adpDocument = props.sourceDocument;
  if (!adpDocument) return;
  if (!isDocument(adpDocument)) return;

  emitter.emit('article-updated', { ...adpDocument, is_read: true });
  if (isAdpDocument(adpDocument)) {
    await api.sendView({
      adpDocument: adpDocument,
      trackingBaseData: {
        pageName: 'survey',
        pageUrl: getCurrentRoutePath(),
        feature: 'survey',
      },
      contentsContext: props.contentsContext,
    });
  }

  if (isUserDocument(adpDocument)) {
    await createUserDocumentViewHistories(adpDocument.id);
    await api.trackingUserDocumentEvent('user_document_view', {
      page: { name: SURVEY_PAGE_NAME },
      feature: SURVEY_PAGE_NAME,
      user_document: { id: adpDocument.id },
      contents_context: props.contentsContext,
      view_type: 'original',
    } as UserDocumentViewInfo);
  }
};

const isPageNumberAvailable = computed(
  () =>
    isUserDocument(props.sourceDocument) &&
    props.sourceDocument.file_type !== 'Word',
);
</script>

<template>
  <VTooltip
    placement="bottom"
    :popper-triggers="['hover', 'focus']"
    :delay="{ show: 0, hide: 200 }"
    class="number-badge c-text c-text--xs"
  >
    <div ref="badgeElement">
      <span class="hidden-char">[</span>
      <span>{{ number }}</span>
      <span class="hidden-char">]</span>
    </div>
    <template #popper>
      <div>
        <p>{{ documentPublisher }}</p>
        <a
          class="help-link"
          :href="documentUrl"
          @click="handleClick"
          target="_blank"
          rel="noopener noreferrer"
          >{{ sourceDocument.title }}</a
        >
      </div>
    </template>
  </VTooltip>
</template>

<style lang="scss" scoped>
// src/assets/global.css.scssのクラスを使用しており、背景色の値を上書き
.number-badge {
  cursor: pointer;
  background: $color-gray1000;
}

.hidden-char {
  font-size: 0px;
}
</style>
