<script lang="ts">
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  onUnmounted,
  PropType,
  reactive,
  ref,
  Ref,
  toRefs,
  watch,
} from 'vue';
import { useRoute } from 'vue-router';
import api from '@/api';
import { Feature } from '@/api/tracking';
import {
  EMPTY_KEYWORD_FEED_MESSAGE,
  THEME_RELATED_NEWS_MAX_COUNT,
} from '@/constants';
import { DgrLoading } from '@stockmarkteam/donguri-ui';
import dayjs, { Dayjs } from 'dayjs';
import FeedArticleMedia from '@/components/common/feed-articles-media.vue';
import ThemeEmptyFeed from '@/components/common/theme-empty-feed.vue';
import ThemeEmptyState from '@/components/theme/theme-empty-state.vue';
import { AdpDocument, Article, ArticleMarkSource, FeedType } from '@/types';
import { articleHideable, themeEditable } from '@/utils/authorization';
import { formatDateForFeedLabel } from '@/utils/formatters';
import { useGroups, useTheme, useUserInfo } from '@/utils/swr';
import { useEmitter, useStore } from '@/utils/vue';
import PastArticles from '../theme/past-articles.vue';

type FeedData = {
  articles: Article[];
  feedCount: number;
  isLoaded: boolean;
  isFetchingData: boolean;
  isViewMoreButtonVisible: boolean;
  remainingArticles: number;
  load: () => void;
  loadMore: () => void;
};
type DateArticles = {
  date: Dayjs;
  dateStr: string;
  dateLabel: string;
  domestic: Ref<FeedData>;
  foreign: Ref<FeedData>;
};

export default defineComponent({
  props: {
    feed: {
      type: String as PropType<
        | 'keyword_feed'
        | 'related_feed'
        | 'personal_news'
        | 'keyword_feed_from_search'
      >,
      required: true,
    },
    hasViewMoreButton: { type: Boolean, default: true },
  },
  components: {
    ThemeEmptyFeed,
    PastArticles,
    FeedArticleMedia,
    ThemeEmptyState,
    DgrLoading,
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const emitter = useEmitter();

    const { feed, hasViewMoreButton } = toRefs(props);
    const feedName = computed(() =>
      feed.value === 'keyword_feed' || feed.value === 'keyword_feed_from_search'
        ? '新着'
        : feed.value === 'related_feed'
          ? '関連'
          : 'パーソナル',
    );
    const feedApi = computed<
      (
        feedType: FeedType,
        date: Dayjs,
        page: number,
        limit: number,
      ) => Promise<{ articles: Article[]; count?: number }>
    >(() =>
      feed.value === 'keyword_feed'
        ? (feedType: FeedType, date: Dayjs, page: number, limit: number) =>
            api.getThemeKeywordFeeds(themeId.value, feedType, date, page, limit)
        : feed.value === 'keyword_feed_from_search'
          ? (feedType: FeedType, date: Dayjs, page: number, limit: number) =>
              api.getThemeKeywordFeedsFromSearch(
                themeId.value,
                'article',
                feedType,
                date,
                page,
                limit,
              ) as Promise<{ articles: Article[]; count: number }>
          : feed.value === 'related_feed'
            ? (feedType: FeedType, date: Dayjs, page: number, limit: number) =>
                api.getThemeRelatedFeeds(
                  themeId.value,
                  feedType,
                  date,
                  page,
                  limit,
                )
            : (feedType: FeedType, date: Dayjs, page: number, limit: number) =>
                api.getPersonalNews(feedType, date, page, limit),
    );
    const themeId = computed(() => Number(route.params.themeId));
    const { data: themeResponse, mutate: mutateTheme } =
      feed.value !== 'personal_news'
        ? useTheme(themeId.value)
        : {
            data: ref({ themes: undefined }),
            mutate: () => Promise.resolve(),
          };
    const theme = computed(() => themeResponse.value?.themes);

    const feedType = computed(() => store.state.feedType.feedType);

    const { data: userInfo } = useUserInfo();
    const { data: groups } = useGroups();

    const hasFeed = computed(() => {
      const isPersonalNews = feed.value === 'personal_news';
      const hasBothFeeds = feedType.value === 'all';
      const hasDomesticFeed =
        feedType.value === 'domestic' && !theme.value?.is_chinese;
      const hasForeignFeed =
        feedType.value === 'foreign' && theme.value?.is_foreign_media_enabled;
      return (
        isPersonalNews || hasBothFeeds || hasDomesticFeed || hasForeignFeed
      );
    });

    const enableToEditTheme = computed(
      () =>
        userInfo.value?.role !== 'viewer' ||
        (theme.value?.access_scope === 'personal' &&
          theme.value?.user_id === userInfo.value?.id),
    );

    const feedLastUpdatedAt = computed(() =>
      theme.value?.feed_last_updated_at
        ? dayjs(theme.value.feed_last_updated_at)
        : dayjs(),
    );

    const isLoadingInitial = ref(true);

    const currentDayFeedCount = ref(0);

    const feedDayCount = ref(0);

    const getFeedData = (
      hasAllFeeds: boolean,
      feedType: 'domestic' | 'foreign',
      date: Dayjs,
    ): FeedData => {
      const initialArticleCount =
        feed.value === 'related_feed'
          ? THEME_RELATED_NEWS_MAX_COUNT
          : hasAllFeeds
            ? 20
            : 200;
      const load = async (articleCount = initialArticleCount) => {
        const data = articlesByDate.find(d => d.date === date)?.[feedType];
        if (!data || !data.value) return;

        data.value.isViewMoreButtonVisible = false;
        data.value.isFetchingData = true;
        const response = await feedApi.value(feedType, date, 1, articleCount);
        data.value.articles = response.articles;
        if (feed.value === 'keyword_feed') {
          const keyword_counts = theme.value?.keyword_feed_counts
            ? theme.value?.keyword_feed_counts[date.format('YYYY-MM-DD')]
            : { domestic: 0, foreign: 0 };
          data.value.feedCount =
            keyword_counts && keyword_counts[feedType]
              ? keyword_counts[feedType]
              : 0;
        } else if (feed.value === 'keyword_feed_from_search') {
          data.value.feedCount = response.count ?? 0;
        } else if (feed.value === 'personal_news') {
          data.value.feedCount = response.count as number;
        } else {
          data.value.feedCount = data.value.articles.length;
        }
        data.value.feedCount -= removedArticles.items.length;
        data.value.remainingArticles =
          data.value.feedCount - data.value.articles.length;
        data.value.isViewMoreButtonVisible =
          hasViewMoreButton.value && data.value.remainingArticles > 0;
        data.value.isFetchingData = false;
        data.value.isLoaded = true;
      };

      return {
        articles: [] as Article[],
        feedCount: 0,
        isLoaded: false,
        isFetchingData: false,
        isViewMoreButtonVisible: false,
        remainingArticles: 0,
        load: load,
        loadMore: async () => {
          const data = articlesByDate.find(d => d.date === date)?.[feedType];
          if (data?.value) {
            // `keyword_feed_from_search` はESを使っているので最大10000件まで取得可能
            await load(Math.min(data.value.feedCount, 10000));
          }
        },
      };
    };

    const emptyFeedData = () => ({
      articles: [] as Article[],
      feedCount: 0,
      isLoaded: true,
      isFetchingData: false,
      isViewMoreButtonVisible: false,
      remainingArticles: 0,
      load: () => undefined,
      loadMore: () => undefined,
    });

    const articlesByDate: DateArticles[] = [] as DateArticles[];
    const pastArticlesDate = ref('');
    const setArticlesForDate = (
      feedLastUpdatedAt: Dayjs,
      dayNumber: number,
    ) => {
      const date = feedLastUpdatedAt.subtract(dayNumber, 'days');

      const data: DateArticles = {
        date: date,
        dateStr: date.format('YYYY-MM-DD'),
        dateLabel: formatDateForFeedLabel(date, feedLastUpdatedAt),
        domestic: ref(
          ['all', 'domestic'].includes(feedType.value)
            ? getFeedData(feedType.value === 'all', 'domestic', date)
            : emptyFeedData(),
        ),
        foreign: ref(
          ['all', 'foreign'].includes(feedType.value) &&
            (feed.value === 'personal_news' ||
              theme.value?.is_foreign_media_enabled)
            ? getFeedData(feedType.value === 'all', 'foreign', date)
            : emptyFeedData(),
        ),
      };
      articlesByDate[dayNumber] = data;
    };

    let feedObserver: IntersectionObserver | undefined = undefined;
    const feedRefs = ref<HTMLElement[] | undefined>();
    const initializeFeedsObserver = () => {
      feedObserver?.disconnect();
      // スクロールしたら次の日のフィードが画面に表示する時にデータを取得する
      feedObserver = new IntersectionObserver(
        (entries, observer) => {
          entries.forEach(entry => {
            if (entry.intersectionRatio > 0) {
              const dateStr = (entry.target as HTMLElement).dataset.dateStr;
              const date = dayjs(dateStr);
              const data = articlesByDate
                .slice(1)
                .find(d => d.date.isSame(date, 'day'));
              if (
                data &&
                data.domestic.value.articles.length === 0 &&
                data.foreign.value.articles.length === 0
              ) {
                data.domestic.value.load();
                data.foreign.value.load();
              }

              observer.unobserve(entry.target);
            }
          });
        },
        { threshold: 0.1, rootMargin: '120px 0px 0px 0px' },
      );
      feedRefs.value?.forEach((el, i) => {
        if (i === 0) return;
        feedObserver?.observe(el);
      });
    };

    const initialize = async () => {
      isLoadingInitial.value = true;
      feedObserver?.disconnect();
      if (
        feed.value !== 'personal_news' &&
        (!theme.value?.id || !hasFeed.value)
      ) {
        isLoadingInitial.value = false;
        return;
      }

      let dateCount = 7; // デフォルト7日間表示
      // キーワードフィードの場合はフィード作成日まで表示。その下に過去記事表示が出る
      if (feed.value === 'keyword_feed' || feed.value === 'related_feed') {
        const sortedDates = Object.keys(
          theme.value?.keyword_feed_counts ?? [],
        ).sort();
        if (sortedDates.length > 0) {
          const initialFeedDate = dayjs(sortedDates[0]);
          dateCount = Math.min(
            7,
            feedLastUpdatedAt.value.diff(dayjs(initialFeedDate), 'day') + 1,
          );
        } else {
          dateCount = 1;
        }
      } else if (feed.value === 'keyword_feed_from_search') {
        dateCount = 28;
      }
      if (dateCount > 0) {
        for (let i = 0; i < dateCount; i++) {
          setArticlesForDate(feedLastUpdatedAt.value, i);
        }
        pastArticlesDate.value = formatDateForFeedLabel(
          feedLastUpdatedAt.value.subtract(dateCount, 'days'),
          feedLastUpdatedAt.value,
        );

        await Promise.all([
          articlesByDate[0].domestic.value.load(),
          articlesByDate[0].foreign.value.load(),
        ]);

        currentDayFeedCount.value =
          articlesByDate[0].domestic.value.feedCount +
          articlesByDate[0].foreign.value.feedCount;
      }
      feedDayCount.value = dateCount;

      isLoadingInitial.value = false;

      await nextTick();

      initializeFeedsObserver();
      initializePastArticlesObserver();
    };

    watch(
      () => theme.value?.id,
      async () => await initialize(),
    );
    watch(
      () => feedType.value,
      async () => {
        removedArticles.items = [];
        await mutateTheme();
        await initialize();
      },
    );

    const allLoaded = computed(() =>
      articlesByDate
        .map(
          data => data.domestic.value.isLoaded && data.foreign.value.isLoaded,
        )
        .reduce((acc, curr) => acc && curr, true),
    );

    onMounted(async () => {
      await initialize();
      emitter.on('article-updated', updateArticle);
      emitter.on('article-removed', removeArticle);
      emitter.on('personal-feed-article-removed', removePersonalNewsArticle);
      emitter.on('personal-feed-article-restored', restorePersonalNewsArticle);
    });

    onUnmounted(() => {
      emitter.off('article-updated', updateArticle);
      emitter.off('article-removed', removeArticle);
      emitter.off('personal-feed-article-removed', removePersonalNewsArticle);
      emitter.off('personal-feed-article-restored', restorePersonalNewsArticle);
      feedObserver?.disconnect();
      pastArticlesObserver?.disconnect();
    });

    const updateArticle = (updatedArticle: AdpDocument) => {
      articlesByDate.forEach(data => {
        [data.domestic.value.articles, data.foreign.value.articles].forEach(
          articles => {
            const index = articles.findIndex(a => {
              return a.id === updatedArticle.id;
            });
            if (index >= 0) {
              articles[index].marks = updatedArticle.marks;
            }
          },
        );
      });
    };

    const removedArticles = reactive({ items: [] as number[] });

    const removeArticle = ({ articleId }: { articleId: number }) => {
      removedArticles.items.push(articleId);
      updateFeedCounts(articleId, -1);
    };
    const restoreArticle = ({ articleId }: { articleId: number }) => {
      const removed = [...removedArticles.items];
      if (removed.includes(articleId)) {
        removedArticles.items = removed.filter(a => a !== articleId);
        updateFeedCounts(articleId, 1);
      }
    };
    const updateFeedCounts = (articleId: number, amount: number) => {
      articlesByDate.forEach((data, i) => {
        const inDomestic = data.domestic.value.articles.find(
          a => a.id === articleId,
        );
        const inForeign = data.foreign.value.articles.find(
          a => a.id === articleId,
        );

        if (inDomestic) {
          data.domestic.value.feedCount += amount;
        }
        if (inForeign) {
          data.foreign.value.feedCount += amount;
        }
        if ((inDomestic || inForeign) && i === 0) {
          currentDayFeedCount.value += amount;
        }
      });
    };

    const removePersonalNewsArticle = (article: Article) =>
      removeArticle({ articleId: article.id });
    const restorePersonalNewsArticle = (article: Article) => {
      restoreArticle({ articleId: article.id });
    };

    const getVisibleArticles = (articles: Article[]) =>
      articles.filter(a => !removedArticles.items.includes(a.id));

    const loadToDate = async (date: Dayjs) => {
      const notLoadedIndicies = articlesByDate
        .filter(
          d =>
            d.date.isAfter(date, 'day') &&
            (!d.domestic.value.isLoaded || !d.foreign.value.isLoaded),
        )
        .map(d => articlesByDate.indexOf(d));
      for (let d of notLoadedIndicies) {
        await Promise.all([
          articlesByDate[d].domestic.value.load(),
          articlesByDate[d].foreign.value.load(),
        ]);
      }
    };

    const dateSelectRefs = ref<HTMLElement[] | undefined>();

    const selectDate = async (dateStr: string) => {
      const targetElement = dateSelectRefs.value?.find(r => {
        return r.dataset.dateStr === dateStr;
      });
      if (!targetElement) return;

      await loadToDate(dayjs(dateStr));

      nextTick(() => {
        // FIXME: スクロールエリアをwindowではなくてこのコンポーネント内に
        //        閉じた上で、element.scrollIntoView()にしたい
        const headerOffset = 112;
        const dividerOffset = 16;
        const targetPosition =
          targetElement.getBoundingClientRect().top +
          window.pageYOffset -
          headerOffset -
          dividerOffset;
        window.scrollTo({ top: targetPosition, behavior: 'smooth' });
      });
    };

    const pastArticlesRef = ref<HTMLElement | undefined>();
    const scrolledPastArticles = ref(false);
    let pastArticlesObserver: IntersectionObserver | undefined = undefined;
    const initializePastArticlesObserver = () => {
      pastArticlesObserver?.disconnect();
      pastArticlesObserver = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (entry.intersectionRatio > 0) {
            scrolledPastArticles.value = true;
            observer.unobserve(entry.target);
          }
        });
      });
      if (pastArticlesRef.value) {
        pastArticlesObserver.observe(pastArticlesRef.value);
      }
    };

    const isInitialStateKeywordFeed = computed(
      () =>
        feed.value === 'keyword_feed' &&
        theme.value?.feed_last_updated_at == null,
    );

    const pastArticlesToDate = computed(() => {
      if (theme.value?.feed_last_updated_at == null) {
        return dayjs(theme.value?.created_at).subtract(1, 'day').endOf('day');
      } else {
        return dayjs().subtract(7, 'day').endOf('day');
      }
    });

    const keywordFeedCount = (feedType: Exclude<FeedType, 'all'>) => {
      const lengthList = articlesByDate.map(
        d => d[feedType].value.articles.length,
      );
      return lengthList.length > 0 ? lengthList.reduce((sum, n) => sum + n) : 0;
    };

    const markSource: ArticleMarkSource =
      feed.value === 'personal_news' ? 'personal_feed' : 'theme';
    const feature: Feature =
      feed.value === 'personal_news' ? 'personal_feed' : feed.value;

    // 記事を非表示にすることができる
    const isArticleHideable = computed(() => {
      const groupId = theme.value?.group_id;
      const themeGroup = groups.value?.groups?.find(x => x.id === groupId);
      return articleHideable(userInfo.value, theme.value, themeGroup);
    });

    // 記事のメディアを除外することができる
    const isSiteExcludable = computed(() => {
      const groupId = theme.value?.group_id;
      const themeGroup = groups.value?.groups?.find(x => x.id === groupId);
      return themeEditable(userInfo.value, theme.value, themeGroup);
    });

    return {
      theme,
      feedName,
      feedType,
      hasFeed,
      isLoadingInitial,
      enableToEditTheme,
      articlesByDate,
      pastArticlesDate,
      allLoaded,
      currentDayFeedCount,
      selectDate,
      markSource,
      pageName: feed.value,
      feature,
      removedArticles,
      getVisibleArticles,
      isInitialStateKeywordFeed,
      scrolledPastArticles,
      pastArticlesToDate,
      keywordFeedCount,
      dateSelectRefs,
      feedRefs,
      pastArticlesRef,
      isArticleHideable,
      isSiteExcludable,
      EMPTY_KEYWORD_FEED_MESSAGE,
    };
  },
});
</script>
<template>
  <div class="feed-articles-container">
    <div
      v-if="hasFeed && !isLoadingInitial && !isInitialStateKeywordFeed"
      class="feed-articles"
    >
      <div
        v-for="(data, i) in articlesByDate"
        ref="feedRefs"
        :data-date-str="data.dateStr"
        :key="data.dateLabel"
      >
        <div v-if="i === 1" class="past-news-title c-title c-title--xm">
          過去の{{ feed === 'related_feed' ? feedName : '' }}ニュース
        </div>
        <div
          ref="dateSelectRefs"
          :data-date-str="data.dateStr"
          class="articles"
        >
          <div>
            <FeedArticleMedia
              v-if="
                (feedType === 'all' || feedType === 'domestic') &&
                (!theme || !theme.is_chinese)
              "
              :articles-by-date="articlesByDate"
              :articles="getVisibleArticles(data.domestic.value.articles)"
              :article-count="data.domestic.value.feedCount"
              :theme="theme"
              :date="data.date"
              :date-str="data.dateStr"
              :date-label="data.dateLabel"
              :feed-data="data.domestic.value"
              :is-visible-empty-feed="
                data.domestic.value.isLoaded &&
                getVisibleArticles(data.domestic.value.articles).length === 0
              "
              :feed="feed"
              :display-feed-type="feedType"
              :theme-feed-type="'domestic'"
              @select-date="selectDate"
            />
          </div>

          <div>
            <FeedArticleMedia
              v-if="feedType === 'all' || feedType === 'foreign'"
              :articles-by-date="articlesByDate"
              :articles="getVisibleArticles(data.foreign.value.articles)"
              :article-count="data.foreign.value.feedCount"
              :theme="theme"
              :date="data.date"
              :date-str="data.dateStr"
              :date-label="data.dateLabel"
              :feed-data="data.foreign.value"
              :is-visible-empty-feed="
                data.foreign.value.isLoaded &&
                getVisibleArticles(data.foreign.value.articles).length === 0
              "
              :feed="feed"
              :display-feed-type="feedType"
              :theme-feed-type="'foreign'"
              @select-date="selectDate"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="isLoadingInitial" class="loading-container">
      <DgrLoading class="loading" />
    </div>
    <template v-else-if="theme">
      <ThemeEmptyFeed
        v-if="!hasFeed"
        class="empty-feed"
        :theme="theme"
        :display-feed-type="feedType"
        :theme-feed-type="feedType"
        :enable-to-edit-theme="enableToEditTheme"
        doc-type="article"
        >{{ EMPTY_KEYWORD_FEED_MESSAGE }}</ThemeEmptyFeed
      >
      <ThemeEmptyState
        v-if="isInitialStateKeywordFeed"
        :is-chinese="theme.is_chinese"
      />
    </template>

    <div
      v-if="theme && feed === 'keyword_feed' && !theme.is_chinese"
      ref="pastArticlesRef"
      class="past-articles"
    >
      <div class="c-title c-title--xm">
        {{ pastArticlesDate }}までのニュース
      </div>
      <div class="spacing-16" />
      <div v-if="isInitialStateKeywordFeed || scrolledPastArticles">
        <template v-if="feedType === 'all' || feedType === 'domestic'">
          <div class="media-title-wrapper">
            <div class="media-label c-title c-title--xm">国内メディア</div>
            <div class="divider"></div>
          </div>
          <PastArticles
            :keywords="theme ? theme.keywords : []"
            :to_date="pastArticlesToDate"
            lang="ja"
            :rank-offset="keywordFeedCount('domestic') + 1"
            :theme="theme"
            :page-name="pageName"
            :mark-source="markSource"
            :feature="feature"
          />
        </template>
        <template v-if="feedType === 'all'">
          <div class="spacing-16" />
          <div class="spacing-12" />
        </template>
        <template v-if="feedType === 'all' || feedType === 'foreign'">
          <div class="media-title-wrapper">
            <div class="media-label c-title c-title--xm">海外メディア</div>
            <div class="divider"></div>
          </div>
          <PastArticles
            v-if="theme.is_foreign_media_enabled"
            :keywords="theme ? theme.keywords : []"
            :to_date="pastArticlesToDate"
            lang="en"
            :rank-offset="keywordFeedCount('foreign') + 1"
            :theme="theme"
            :page-name="pageName"
            :mark-source="markSource"
            :feature="feature"
          />
          <ThemeEmptyFeed
            v-else
            display-feed-type="foreign"
            theme-feed-type="foreign"
            doc-type="article"
            :theme="theme"
            :enable-to-edit-theme="enableToEditTheme"
          />
        </template>
        <div class="spacing-16" />
      </div>
      <div v-if="scrolledPastArticles" class="induction">
        <div class="image-wrap">
          <img class="image" src="@/assets/login_visual.jpg" />
        </div>
        <div class="spacing-12" />
        <div class="message c-title c-title--xm">
          気になる記事は見つかりましたか？
        </div>
        <div class="spacing-12" />
        <div class="message c-text c-text--m">
          あなたの興味・関心にあった記事が少ないなと感じたら、<br />
          キーワードを追加してみてください。
        </div>
        <div class="spacing-12" />
        <div class="message c-text c-text--m">
          <router-link
            class="green-600 link"
            :to="{ path: `/themes/${theme.id}/edit` }"
          >
            キーワードを追加する
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.feed-articles-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 540px;

  .title {
    display: flex;
    align-items: center;
    height: 24px;
    margin-bottom: 16px;

    .count {
      color: #b3b3b3;
    }
  }

  .media-title-wrapper {
    background: #fff;
  }

  .divider {
    width: auto;
    border-bottom: 0.5px solid #e6e6e6;
    margin: 0px 16px 0px 16px;
  }

  .feed-articles {
    display: flex;
    flex-direction: column;
  }

  .date-divider {
    position: relative;
    top: 16px;
    width: 616px;
    height: 1px;
    background: #e6e6e6;
  }

  .date-select {
    position: sticky;
    top: 112px;
    z-index: var(--z-dropdown);
    display: flex;
    justify-content: center;
  }

  .article-card {
    margin-bottom: 8px;
  }

  .articles {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    .document-card {
      border: 0;
    }
  }

  .view-more-button {
    display: inline-flex;
    align-self: center;
    padding: 4px 12px;
    border-radius: 4px;
    border: 1px solid #1da482;
    background: #fff;
    color: #1da482;
    cursor: pointer;
  }

  .loading {
    align-self: center;
  }
  .loading-spacer {
    padding-bottom: 80vh;
  }

  .domestic-title {
    margin-bottom: 12px;
  }

  .date-select-box {
    :deep(.c-selectBox) {
      border: none;
    }
  }

  .loading-container {
    width: 616px;
    display: flex;
    flex-direction: column;
  }

  .green-600 {
    color: $color-green600;
  }

  .induction {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    padding: 24px 16px;
    margin-top: 28px;
    border: 1px solid $color-gray400;
    border-radius: 4px;
    box-sizing: border-box;

    .image-wrap {
      display: flex;
      justify-content: center;
      .image {
        width: 120px;
        height: 120px;
      }
    }

    .message {
      text-align: center;
    }
    .link {
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .media-label {
    display: flex;
    align-items: center;
    position: sticky;
    top: 105px;
    background: #fff;
    z-index: 1;
    height: 40px;
    padding-left: 16px;
  }

  .empty-feed {
    margin-bottom: 16px;
  }

  .past-news-title {
    margin-bottom: 24px;
  }
}
</style>
