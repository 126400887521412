<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import api from '@/api';
import { DgrIcon } from '@stockmarkteam/donguri-ui';
import Header from '@/components/layouts/header.vue';
import SurveyInput from '@/components/survey/input/survey-input.vue';
import { DocType, SearchScope, SurveyInputValue } from '@/types';
import { useCurrentRoutePath } from '@/utils/composables/useCurrentRoutePath';
import { SURVEY_PAGE_NAME } from '@/utils/survey/constants';
import { useTeamInfo } from '@/utils/swr';
import { useEmitter } from '@/utils/vue';

const emitter = useEmitter();
const router = useRouter();
const route = useRoute();
const { getCurrentRoutePath } = useCurrentRoutePath();

// 入力値のクエリパラメータをqueryにしていないのは、ヘッダーの検索窓の値でqueryを使用しており、そちらにも値が入ってしまうため
const startSurvey = async ({ question, filterInfo }: SurveyInputValue) => {
  const surveySession = await api.createSurveySession(question);
  const surveySessionId = surveySession.session_id;

  router.push({
    name: 'surveySession',
    params: { survey_session_id: surveySessionId },
    query: {
      from: route.query.from ?? undefined,
      question,
      searchScope: filterInfo.searchScope,
      docTypes: filterInfo.docTypes,
      specifiedFiles: filterInfo.specifiedFiles.map(file =>
        JSON.stringify(file),
      ),
    },
  });

  // サイドメニューの履歴に反映させるため通知
  emitter.emit('update-survey-session', {
    session_id: surveySessionId,
    title: question,
    updated_at: new Date().toISOString(),
  });
};

const initialSearchScope = ref<SearchScope>();
const initialDocTypes = ref<DocType[]>();

const { data: teamInfo } = useTeamInfo();
const enableTechnicalLiterature = computed(
  () => teamInfo.value?.enable_technical_literature ?? false,
);

onMounted(async () => {
  await api.trackPageView({
    pageName: SURVEY_PAGE_NAME,
    pageUrl: getCurrentRoutePath(),
  });
  if (route.query.mode === 'user_marks') {
    initialSearchScope.value = 'user_marks';
    initialDocTypes.value = enableTechnicalLiterature.value
      ? ['article', 'research_paper', 'patent']
      : ['article'];
  }
});
</script>

<template>
  <div class="content-wrapper">
    <Header title="要約" :is-one-line="true" header-width="912px" />
    <div class="content">
      <p class="c-title c-title--l">なんでも質問してください。</p>
      <p class="c-title c-title--l with-tooltip-annotation">
        ニュースや論文、社内情報から要約を生成します。
        <VTooltip placement="top">
          <DgrIcon name="question-circle" size="small" />
          <template #popper>
            <span
              >要約生成に使用する情報はニュース、レポート、論文、特許、社内情報です。<br />
              ※論文、特許、社内情報はご契約内容によりサポート対象外となる場合がございます。</span
            >
          </template>
        </VTooltip>
      </p>
      <SurveyInput
        :enable-transform-input="false"
        :initial-search-scope="initialSearchScope"
        :initial-doc-types="initialDocTypes"
        @submit="startSurvey"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.content-wrapper {
  width: 100%;
  margin: -24px 0 0 0;

  .content {
    padding: 32px;
    max-width: 912px;
    margin: 0 auto;

    .with-tooltip-annotation {
      display: flex;
      align-items: center;
    }
  }
}
</style>
