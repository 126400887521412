import { Ref, ref } from 'vue';
import { getUserDocumentsByIds } from '@/api/teamSearch';
import dayjs from 'dayjs';
import Dexie, { Table } from 'dexie';
import {
  FileNameSearchResponse,
  UserDocumentActionHistory,
  UserDocumentEntity,
} from '@/types';

interface UseUserDocumentHistoriesReturn {
  fetchLatestSpecifiedFileHistories: () => Promise<void>;
  createSpecifiedFileHistories: (id: string) => Promise<void>;
  fetchLatestUserDocumentViewHistories: () => Promise<void>;
  createUserDocumentViewHistories: (id: string) => Promise<void>;
  userDocumentViewHistories: Ref<Array<FileNameSearchResponse>>;
  specifiedFileHistories: Ref<Array<FileNameSearchResponse>>;
}

interface QuotaExceededError extends DOMException {
  name: 'QuotaExceededError';
}

class UserDocumentActionHistoryDB extends Dexie {
  userDocumentViewHistories!: Table<UserDocumentActionHistory, string>;
  specifiedFileHistories!: Table<UserDocumentActionHistory, string>;

  constructor(dbName: string) {
    super(dbName);
    this.version(2).stores({
      userDocumentViewHistories: 'id,createdAt',
      specifiedFileHistories: 'id,createdAt',
    });
  }
}

/**
 * テーブルごとに共通処理をまとめたヘルパー。
 */
async function createHistory(
  db: UserDocumentActionHistoryDB,
  table: Table<UserDocumentActionHistory, string>,
  id: string,
  maintainLimitFn: () => Promise<void>,
  deleteFn: (id: string) => Promise<void>,
): Promise<void> {
  await db.transaction('rw', table, async () => {
    await deleteFn(id);
    await table.add({ id, createdAt: new Date().toISOString() });
    await maintainLimitFn();
  });
}

async function deleteHistory(
  table: Table<UserDocumentActionHistory, string>,
  id: string,
): Promise<void> {
  await table.where('id').equals(id).delete();
}

async function maintainTableLimit(
  table: Table<UserDocumentActionHistory, string>,
  limit: number,
): Promise<void> {
  const totalCount = await table.count();
  if (totalCount > limit) {
    const overCount = totalCount - limit;
    const oldestIds = await table
      .orderBy('createdAt')
      .limit(overCount)
      .primaryKeys();
    await table.bulkDelete(oldestIds);
  }
}

async function cleanupHistories(
  table: Table<UserDocumentActionHistory, string>,
  ratio: number,
): Promise<void> {
  const totalItems = await table.count();
  const deleteCount = Math.ceil(totalItems * ratio);
  const itemsToDelete = await table
    .orderBy('createdAt')
    .limit(deleteCount)
    .primaryKeys();
  await table.bulkDelete(itemsToDelete);
}

function isQuotaExceededError(error: unknown): error is QuotaExceededError {
  if (error instanceof Dexie.DexieError) {
    return (error.inner as DOMException)?.name === 'QuotaExceededError';
  }
  return (error as QuotaExceededError).name === 'QuotaExceededError';
}

/**
 * テーブルごとに共通の最新履歴取得処理
 */
async function fetchLatestHistories(
  table: Table<UserDocumentActionHistory, string>,
  itemLimit: number,
  docsRef: Ref<FileNameSearchResponse[]>,
): Promise<void> {
  try {
    let offset = 0;
    const FETCH_LIMIT = 10;
    const fetchedDocs: UserDocumentEntity[] = [];

    while (fetchedDocs.length < itemLimit) {
      const results: UserDocumentActionHistory[] = await table
        .orderBy('createdAt')
        .reverse()
        .offset(offset)
        .limit(FETCH_LIMIT)
        .toArray();

      if (!results.length) break;
      offset += FETCH_LIMIT;

      // 権限がなくなっていたり、ファイルがなくなっている可能性があるため、1度OpenSearchから最新のファイル情報を取得する
      const validUserDocuments = await getUserDocumentsByIds(
        results.map(r => r.id),
      );

      const validIds = validUserDocuments.map(doc => doc.id);
      const invalidIds = results
        .filter(r => !validIds.includes(r.id))
        .map(r => r.id);
      if (invalidIds.length > 0) await table.bulkDelete(invalidIds);
      fetchedDocs.push(...validUserDocuments);
    }

    fetchedDocs.splice(itemLimit);
    docsRef.value = fetchedDocs.map(doc => ({
      id: doc.id,
      filename: doc.title,
      file_type: doc.file_type,
      url: doc.url,
      modified_at: dayjs(doc.modified_at).format('YYYY-MM-DD'),
      last_updated_by: doc.last_updated_by,
    }));
  } catch (error) {
    throw new Error(`fetchLatestHistories error: ${error}`);
  }
}

/**
 * 「ファイル指定履歴」 と 「社内情報閲覧履歴」 をまとめて扱うカスタムフック
 */
export const useUserDocumentActionHistories = (
  userId: number | null,
): UseUserDocumentHistoriesReturn => {
  const dbName = `userDocumentActionHistoryDB-${userId}`;
  const db = new UserDocumentActionHistoryDB(dbName);

  const MAX_TABLE_ITEM_COUNTS = 30;
  const MAX_ITEM_COUNTS_PER_VIEW = 3;

  const specifiedFileHistories = ref<FileNameSearchResponse[]>([]);
  const userDocumentViewHistories = ref<FileNameSearchResponse[]>([]);

  // --------------------------------------
  // ファイル指定履歴
  // --------------------------------------
  const deleteSpecifiedFileHistories = async (id: string): Promise<void> => {
    try {
      await deleteHistory(db.specifiedFileHistories, id);
    } catch (error) {
      throw new Error(`deleteSpecifiedFileHistories error: ${error}`);
    }
  };

  const maintainSpecifiedFileHistoryLimit = async (): Promise<void> => {
    try {
      await maintainTableLimit(
        db.specifiedFileHistories,
        MAX_TABLE_ITEM_COUNTS,
      );
    } catch (error) {
      throw new Error(`maintainSpecifiedFileHistoryLimit error: ${error}`);
    }
  };

  const createSpecifiedFileHistories = async (id: string): Promise<void> => {
    try {
      await createHistory(
        db,
        db.specifiedFileHistories,
        id,
        maintainSpecifiedFileHistoryLimit,
        deleteSpecifiedFileHistories,
      );
    } catch (error) {
      if (isQuotaExceededError(error)) {
        try {
          await cleanupHistories(db.specifiedFileHistories, 0.5);
          await createHistory(
            db,
            db.specifiedFileHistories,
            id,
            maintainSpecifiedFileHistoryLimit,
            deleteSpecifiedFileHistories,
          );
        } catch (retryError) {
          throw new Error(
            `createSpecifiedFileHistories retryError: ${retryError}`,
          );
        }
      } else {
        throw new Error(`createSpecifiedFileHistories error: ${error}`);
      }
    }
  };

  const fetchLatestSpecifiedFileHistories = async (): Promise<void> => {
    try {
      await fetchLatestHistories(
        db.specifiedFileHistories,
        MAX_ITEM_COUNTS_PER_VIEW,
        specifiedFileHistories,
      );
    } catch (error) {
      throw new Error(`fetchLatestSpecifiedFileHistories error: ${error}`);
    }
  };

  // --------------------------------------
  // 社内情報閲覧履歴
  // --------------------------------------
  const deleteUserDocumentHistories = async (id: string): Promise<void> => {
    try {
      await deleteHistory(db.userDocumentViewHistories, id);
    } catch (error) {
      throw new Error(`deleteUserDocumentHistories error: ${error}`);
    }
  };

  const maintainUserDocumentHistoryLimit = async (): Promise<void> => {
    try {
      await maintainTableLimit(
        db.userDocumentViewHistories,
        MAX_TABLE_ITEM_COUNTS,
      );
    } catch (error) {
      throw new Error(`maintainUserDocumentHistoryLimit error: ${error}`);
    }
  };

  const createUserDocumentViewHistories = async (id: string): Promise<void> => {
    try {
      await createHistory(
        db,
        db.userDocumentViewHistories,
        id,
        maintainUserDocumentHistoryLimit,
        deleteUserDocumentHistories,
      );
    } catch (error) {
      if (isQuotaExceededError(error)) {
        try {
          await cleanupHistories(db.userDocumentViewHistories, 0.5);
          await createHistory(
            db,
            db.userDocumentViewHistories,
            id,
            maintainUserDocumentHistoryLimit,
            deleteUserDocumentHistories,
          );
        } catch (retryError) {
          throw new Error(
            `createUserDocumentViewHistories retryError: ${retryError}`,
          );
        }
      } else {
        throw new Error(`createUserDocumentViewHistories error: ${error}`);
      }
    }
  };

  const fetchLatestUserDocumentViewHistories = async (): Promise<void> => {
    try {
      await fetchLatestHistories(
        db.userDocumentViewHistories,
        MAX_ITEM_COUNTS_PER_VIEW,
        userDocumentViewHistories,
      );
    } catch (error) {
      throw new Error(`fetchLatestUserDocumentViewHistories error: ${error}`);
    }
  };

  return {
    fetchLatestSpecifiedFileHistories,
    createSpecifiedFileHistories,
    fetchLatestUserDocumentViewHistories,
    createUserDocumentViewHistories,
    userDocumentViewHistories,
    specifiedFileHistories,
  };
};
