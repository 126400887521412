<script setup lang="ts">
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { MYPAGE_ROUTE_NAMES } from '@/routers/constants';
import ItemList from '@/components/menu/common/item-list.vue';
import SectionWrapper from '@/components/menu/sections/section-wrapper.vue';
import signout from '@/utils/signout';
import { useUserInfo } from '@/utils/swr';
import { useGTag } from '@/utils/vue';

type ItemListProps = InstanceType<typeof ItemList>['$props'];
type ItemsProps = ItemListProps['items'];

const gtag = useGTag();
const route = useRoute();
const router = useRouter();

const { data: userInfo } = useUserInfo();

const myPageItems = computed<ItemsProps>(() => [
  {
    id: 'mypage',
    name: 'トップ',
    route: { name: MYPAGE_ROUTE_NAMES.profile },
    isSelectedExactRoute: true,
    isSelected: [
      MYPAGE_ROUTE_NAMES.profile,
      MYPAGE_ROUTE_NAMES.marks,
      MYPAGE_ROUTE_NAMES.comments,
      MYPAGE_ROUTE_NAMES.views,
    ].some(v => v === route.name), // includesだとTypeErrorになるのでsomeで代用
  },
  {
    id: 'export',
    name: 'エクスポート',
    route: {
      name: MYPAGE_ROUTE_NAMES.export,
    },
  },
]);

const settingItems = computed<ItemsProps>(() => {
  const baseSettingItems = [
    {
      id: 'settings_profile',
      name: 'プロフィール',
      route: { name: 'userSettingsProfile' },
    },
    {
      id: 'settings_news',
      name: 'ニュース配信',
      route: { name: 'userSettingsNews' },
    },
    {
      id: 'settings_mail',
      name: 'メール配信',
      route: { name: 'userSettingsEmail' },
    },
    {
      id: 'settings_notification',
      name: '通知',
      route: { name: 'userSettingsNotification' },
    },
  ];
  return userInfo.value?.saml_federation_activated
    ? baseSettingItems
    : [
        ...baseSettingItems,
        {
          id: 'settings_password',
          name: 'パスワード',
          route: { name: 'userSettingsPassword' },
        },
      ];
});
</script>
<template>
  <SectionWrapper>
    <ItemList title="マイページ" :items="myPageItems" />
    <ItemList title="個人の設定" :items="settingItems" />
    <button
      @click.prevent="async () => await signout(gtag, router)"
      class="logout-btn c-btn c-btn--small"
    >
      ログアウト
    </button>
  </SectionWrapper>
</template>
<style lang="scss" scoped>
.logout-btn {
  width: fit-content;
}
</style>
