import { GroupActivitySortType } from '@/types';

const SELECTED_IS_SHOW_COMMENT_FILTER_ITEM = 'selectedIsShowCommentFilterItem';
const SELECTED_SORT_FILTER_ITEM = 'selectedSortFilterItem';

export const groupActivitiesQueryItem = {
  getComment(): 'showComment' | 'hideComment' | null {
    return localStorage.getItem(SELECTED_IS_SHOW_COMMENT_FILTER_ITEM) as
      | 'showComment'
      | 'hideComment'
      | null;
  },
  setComment(value: 'showComment' | 'hideComment'): void {
    localStorage.setItem(SELECTED_IS_SHOW_COMMENT_FILTER_ITEM, value);
  },
  getSort(): GroupActivitySortType | null {
    let sort = localStorage.getItem(SELECTED_SORT_FILTER_ITEM) as
      | GroupActivitySortType
      | 'updatedAt'
      | 'latestedAt'
      | null;

    // 古いデータが保存されていた場合は更新する
    if (sort === 'updatedAt') {
      sort = 'updated';
      localStorage.setItem(SELECTED_SORT_FILTER_ITEM, sort);
    }
    if (sort === 'latestedAt') {
      sort = 'created';
      localStorage.setItem(SELECTED_SORT_FILTER_ITEM, sort);
    }

    return sort;
  },
  setSort(value: GroupActivitySortType): void {
    localStorage.setItem(SELECTED_SORT_FILTER_ITEM, value);
  },
};
