import { getDocumentsCount } from '@/api/document';
import { userDocumentSearchCache } from '@/apiCache';
import { USER_DOCUMENT_SEARCH_EXPANDED_QUERY_CACHE_STORE_NAME } from '@/constants';
import axios from 'axios';
import dayjs from 'dayjs';
import Qs from 'qs';
import {
  FileNameSearchResponse,
  OnlyTrackingParam,
  UserDocument,
  UserDocumentSearchRequestParam,
  UserDocumentSearchResultResponse,
} from '@/types';
import { searchExpandedQueryCache } from '@/utils/searchExpandedQueryCache';
import {
  getEventDataForTeamSearchAPI,
  trackingSearchEvent,
} from '@/utils/trackingHelper';

export async function userDocumentSearch(
  searchParams: UserDocumentSearchRequestParam & OnlyTrackingParam,
): Promise<{
  result_count: number;
  search_tracking_session_id: string;
  expanded_queries: string[];
  articles: UserDocument[];
  exceed_max_result_window: boolean | null;
  last_updated_by: string[];
}> {
  let data: UserDocumentSearchResultResponse;
  const queryStr = Qs.stringify(searchParams, { arrayFormat: 'brackets' });
  const url = `/team_search?${queryStr}`;

  // 同じ検索結果でも初回以降の検索ではcached_expanded_queriesが新たに追加されるため、
  // searchCacheはcached_expanded_queriesを除いたパラメータをキーとしてキャッシュする
  // また計測に関するパラメータは除外する
  const {
    cached_expanded_queries: _c,
    feature: _f,
    request_id: _r,
    search_query: _s,
    rank: _ra,
    ...searchCacheParams
  } = searchParams;
  const searchCacheKey = JSON.stringify(searchCacheParams);
  const cache = userDocumentSearchCache.getCache(searchCacheKey);

  if (cache) {
    data = cache;
  } else {
    data = (await axios.get<UserDocumentSearchResultResponse>(url)).data;
    if (
      !searchParams.cached_expanded_queries &&
      searchParams.query &&
      data.expanded_queries &&
      data.expanded_queries.length > 0
    ) {
      searchExpandedQueryCache.setCache(
        searchParams.query,
        data.expanded_queries,
        USER_DOCUMENT_SEARCH_EXPANDED_QUERY_CACHE_STORE_NAME,
      );
      searchParams.cached_expanded_queries = data.expanded_queries;
    }
    if (data.search_tracking_session_id)
      userDocumentSearchCache.setCache(searchCacheKey, data);
  }

  // 計測
  await trackingSearchEvent(
    'user_document_search',
    getEventDataForTeamSearchAPI(
      searchParams,
      data.search_articles,
      data.search_result_count,
      data.search_tracking_session_id,
    ),
  );

  return {
    result_count: data.search_result_count,
    search_tracking_session_id: data.search_tracking_session_id,
    expanded_queries: data.expanded_queries,
    articles: data.search_articles,
    exceed_max_result_window: data.exceed_max_result_window,
    last_updated_by: data.last_updated_by,
  };
}

export async function allUserDocumentsCount(query: string): Promise<number> {
  return getDocumentsCount(
    query,
    '/team_search/all_user_documents_count',
    USER_DOCUMENT_SEARCH_EXPANDED_QUERY_CACHE_STORE_NAME,
  );
}

export const getUserDocumentsByIds = async (
  userDocumentIds: Array<string>,
): Promise<Array<UserDocument>> => {
  const { data } = await axios.get<Array<UserDocument>>(
    `/team_search/user_documents?${Qs.stringify({ ids: userDocumentIds }, { arrayFormat: 'brackets' })}`,
  );
  return data;
};

export async function getUserDocumentById(
  userDocumentId: string,
): Promise<UserDocument> {
  const { data } = await axios.get<Array<UserDocument>>(
    `/team_search/user_documents?${Qs.stringify({ ids: [userDocumentId] }, { arrayFormat: 'brackets' })}`,
  );
  return data[0];
}

export const fileNameSearch = async (
  query: string,
  page: number,
  limit: number,
): Promise<Array<FileNameSearchResponse>> => {
  const params = {
    query,
    page,
    limit,
    lang: 'ja',
  };

  const { data } = await axios.get<{
    search_articles: Array<FileNameSearchResponse>;
  }>(
    `/team_search/file_name_search?${Qs.stringify(params, {
      arrayFormat: 'brackets',
    })}`,
  );

  const foundedFiles: Array<FileNameSearchResponse> = data.search_articles.map(
    file => ({
      ...file,
      modified_at: dayjs(file.modified_at).format('YYYY-MM-DD'),
    }),
  );
  return foundedFiles;
};
