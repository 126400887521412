<script setup lang="ts">
import {
  computed,
  onBeforeUnmount,
  onMounted,
  onUnmounted,
  Ref,
  ref,
  toRaw,
  UnwrapRef,
  watch,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import api from '@/api';
import { PageName } from '@/api/tracking';
import {
  FIRST_PUBLISHED_AT,
  pestNames,
  pests,
  searchPresetActivity,
} from '@/constants';
import { DgrIcon, DgrLoading } from '@stockmarkteam/donguri-ui';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import ActivitiesFilter from '@/components/activities/activities-filter.vue';
import AdpDocumentCard from '@/components/common/adp-document-card.vue';
import AdpDocumentInfoDefault from '@/components/common/adp-document/adp-document-info-default.vue';
import AdpDocumentInfo from '@/components/common/adp-document/adp-document-info.vue';
import SearchBar from '@/components/common/molecules/search-bar.vue';
import SelectedTagList from '@/components/common/multi-select/selected-tag-list.vue';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import SwrScrollingPagination from '@/components/common/swr-scrolling-pagination.vue';
import Content from '@/components/layouts/content.vue';
import Header from '@/components/layouts/header.vue';
import {
  AdpDocument,
  Article,
  ArticleUpdateOption,
  DocType,
  FilterCheckBoxItem,
  FilterSelectBoxItem,
  Lang,
  MemoFilterValue,
  SearchDate,
  SelectedLangsForTrackingType,
  Tag,
  UserSettings as UserSettingsType,
} from '@/types';
import { isEmptyObject, Pagination } from '@/utils';
import { getToday } from '@/utils/dates';
import {
  isMypageRestricted as isMypageRestrictedUtil,
  mypageAccessScope as mypageAccessScopeUtil,
} from '@/utils/mypageAccessScope';
import { buildNewsCategoriesByPest } from '@/utils/news_category';
import { selectArticle } from '@/utils/selectArticle';
import {
  STATES,
  useMarkedArticleCountsMypage,
  useMarkedArticlesMypage,
  useMypageNewsCategories,
  useUserActionCount,
  useUserOrganizationTags,
} from '@/utils/swr';
import { getTabs } from '@/utils/user';
import { userSession } from '@/utils/userSession';
import { useEmitter, useStore } from '@/utils/vue';
import ActivitiesSectionedCheckboxFilter from '../activities/activities-sectioned-checkbox-filter.vue';
import DropdownDateRangeCalendar from '../common/dropdown-date-range-calendar.vue';
import { featureFlags } from '@/featureFlags';

const LIMIT = 10;
const PAGE_NAME: PageName = 'user';

const store = useStore();
const emitter = useEmitter();
const route = useRoute();
const router = useRouter();
const { createSnackbar } = useSnackbar();

const uId = computed(() => userSession.getUserId() ?? undefined);

const { data: userOrganizationTags } = useUserOrganizationTags(uId);

const { mutate: mutateMarkCount } = useUserActionCount(uId, 'mark');

const pagination = ref<Pagination | undefined>(undefined);
const userInfo = computed(() => store.state.userInfo.userInfo);
const targetUserInfo = computed(() => store.state.userActions.targetUserInfo);
const isSharing = computed(() => store.state.modal.isSharing);
const isOpenTagMenu = ref(false);
const selectedArticles = ref<AdpDocument[]>([]);
const articleTags = ref<Tag[]>([]);
const targetTags = ref<string[]>([]);
const targetUserSettings = ref<UserSettingsType | null>(null);

const abortController = new AbortController();
onMounted(async () => {
  await setUp();
  emitter.on('article-updated', handleMarkCountUpdate);
  emitter.on('add-article-for-article-list', addArticleForArticleList);
  emitter.on('remove-article-for-article-list', removeArticleForArticleList);
});

onUnmounted(() => {
  emitter.off('article-updated', handleMarkCountUpdate);
  emitter.off('add-article-for-article-list', addArticleForArticleList);
  emitter.off('remove-article-for-article-list', removeArticleForArticleList);
});

const handleMarkCountUpdate = () => {
  mutateMarkCount();
};

const setUp = async () => {
  if (uId.value === undefined) {
    return;
  }
  await store.dispatch('userActions/fetchTargetUserInfo', uId.value);
  targetUserSettings.value = await api.getUserSettings(uId.value);
  fetchArticlesWithPaginateReset();
  articleTags.value = await api.fetchUserArticleTags(uId.value);
  await trackPageView();
};

const selectedMemoExistsTypeForApiParam = computed(() => {
  const selectedMemoType = memoFilterItems.value.find(item => item.isSelected);
  if (selectedMemoType?.value === undefined || selectedMemoType.value === 'all')
    return undefined;
  return selectedMemoType.value === 'existsMemo';
});

const updateSearchKeyword = async () => {
  searchQueryForApi.value = searchQuery.value;
};

watch(isSharing, newVal => {
  if (newVal) {
    store.commit('modal/setIsSharing', false);
  }
});

onBeforeUnmount(() => {
  pagination.value?.removeEvent();
  abortController.abort();
});

const isMypageRestricted = computed(() => {
  // マイページ公開制限
  if (!targetUserSettings.value) {
    return false;
  }
  if (isEmptyObject(targetUserInfo.value)) {
    return false;
  }

  return isMypageRestrictedUtil(
    userInfo.value,
    targetUserInfo.value,
    targetUserSettings.value,
  );
});

const mypageAccessScope = computed(() => {
  // マイページ公開範囲
  if (!targetUserSettings.value) {
    return '';
  }

  return mypageAccessScopeUtil(targetUserSettings.value);
});

const selectedArticle = computed<AdpDocument | undefined>(() => {
  if (selectedArticles.value.length === 1) {
    return selectedArticles.value[0];
  }
  return undefined;
});

const articleTagList = computed(() => {
  return articleTags.value.map(t => ({
    ...t,
    id: t.name,
  }));
});

const articleSelected = (id: number): boolean | undefined => {
  return selectedArticles.value.map(a => a.id).includes(id);
};

const articleHovered = (id: number): boolean | undefined => {
  return !selectedArticles.value.map(a => a.id).includes(id);
};

const clickArticle = (e: MouseEvent, article: AdpDocument): void => {
  selectedArticles.value = selectArticle(e, article, selectedArticles.value);
};

const updateArticle = async (
  article: AdpDocument,
  updateOption: ArticleUpdateOption,
): Promise<void> => {
  try {
    if (updateOption.shouldUpdateMemo) {
      if (article.memo) {
        await api.updateUserArticleMemo(
          article.id,
          article.doc_type,
          article.memo,
          article.lang,
        );
      } else {
        await api
          .deleteUserArticleMemo(article.id, article.doc_type, article.lang)
          .catch((err: AxiosError) => {
            if (err.response?.status !== 404) {
              throw err;
            }
          });
      }
    }
    if (updateOption.shouldUpdateTags) {
      await api.updateUserArticleTags(
        article.id,
        article.doc_type,
        article.tags ?? [],
        article.lang,
      );
    }
    await api.trackEvent(
      'set_article_option',
      {
        pageName: PAGE_NAME,
        pageUrl: route.fullPath,
        feature: 'my_marks',
      },
      article.id,
      undefined,
      {
        tags: article.tags,
        memo: article.memo,
      },
    );
    selectedArticles.value = selectedArticles.value.map(selectedArticle =>
      selectedArticle.id === article.id ? article : selectedArticle,
    );
    emitter.emit('article-updated', article);
    updateArticleForArticleList(article);
    createSnackbar({
      message: '記事情報を更新しました',
      type: 'success',
    });
  } catch {
    createSnackbar({
      message: '記事情報を更新できませんでした',
      type: 'error',
    });
  }
};

watch(
  () => uId?.value,
  () => setUp(),
);

const themeId = (article: AdpDocument) => {
  if (article.marks.length === 0) return 0;
  return article.marks[0].theme_id ?? undefined;
};

const removeTag = (tagName: string) => {
  handleTagFilterArticles(targetTags.value.filter(t => t !== tagName));
};

const handleTagFilterArticles = (tags: string[]) => {
  isOpenTagMenu.value = false;
  targetTags.value = tags;
  fetchArticlesWithPaginateReset();
};

const trackPageView = async () => {
  const previousRoute = store.state.route.previousRoute;
  const hasPreviousRoute = !!previousRoute?.name;
  const isSnackbar =
    hasPreviousRoute &&
    String(previousRoute.params.userId) !== String(uId.value);
  await api.trackPageView({
    pageName: PAGE_NAME,
    pageUrl: route.fullPath,
    fromUrl: previousRoute?.path ?? null,
    fromSnackbarLink: isSnackbar ? 'article_mark' : undefined,
  });
};

const trackingSessionId = ref<string | undefined>(undefined);

const itemsFetched = (data: {
  marked_articles: Article[];
  has_next_page: boolean;
  tracking_session_id: string | undefined;
}) => {
  if (data.tracking_session_id) {
    trackingSessionId.value = data.tracking_session_id;
  }
};

const contentsContext = computed(() => {
  return {
    event_name: 'my_activities_view',
    session_id: trackingSessionId.value,
  } as const;
});

watch(trackingSessionId, () => {
  if (trackingSessionId.value) {
    trackFilterActions();
  }
});

const trackFilterActions = async () => {
  const period = {
    from: formattedStartDate.value,
    to: formattedEndDate.value,
  };
  await api.trackEvent(
    'my_activities_view',
    {
      pageName: PAGE_NAME,
      pageUrl: route.fullPath,
    },
    undefined,
    undefined,
    {
      request: {
        tags: selectedTags.value,
        // NOTE: メモがあるかないかのフィルター選択値を計測ログでは'article_type' として使用している （経緯は不明）
        article_type: memoFilterLabel.value,
        lang: selectedLangsForTracking.value,
        query: searchQuery.value,
        data_sources: selectedDocTypesForTracking.value,
        period: dateRangeKeyForTracking.value,
        period_detail: dateRangeKeyForTracking.value ? null : period,
        categories: pestNewsCategoryFilterTrackingData.value,
      },
      session_id: trackingSessionId.value,
    },
  );
};

// 検索窓
const searchQuery = ref<string>('');
const handleChangeQuery = (query: string) => {
  searchQuery.value = query;
};
const searchQueryForApi = ref<string>();

// 絞り込みフィルター
const selectedTags = ref<string[]>([]);
const memoFilterItems = ref<FilterSelectBoxItem<MemoFilterValue>[]>([
  {
    value: 'all',
    label: 'すべての記事',
    isSelected: false,
  },
  {
    value: 'existsMemo',
    label: 'マイメモした記事',
    isSelected: false,
  },
  {
    value: 'noMemos',
    label: 'マイメモしていない記事',
    isSelected: false,
  },
]);
const articleLangs = ref<FilterCheckBoxItem<Lang>[]>([
  { label: '国内', value: 'ja', isChecked: false },
  { label: '海外', value: 'en', isChecked: false },
]);
const docTypes = ref<FilterCheckBoxItem<DocType>[]>([
  { label: 'ニュース', value: 'article', isChecked: false },
  { label: '論文', value: 'research_paper', isChecked: false },
  { label: '特許', value: 'patent', isChecked: false },
]);

const memoFilterLabel = computed<string>(() => {
  const selectedItem = memoFilterItems.value.find(item => item.isSelected);
  return selectedItem ? selectedItem.label : memoFilterItems.value[0].label;
});

const selectedLangForApiParam = computed(() => {
  return articleLangs.value
    .filter(lang => lang.isChecked)
    .map(lang => lang.value);
});

const selectedLangsForTracking = computed<SelectedLangsForTrackingType>(() => {
  const checkedLangs: Array<Lang> = articleLangs.value
    .filter(data => data.isChecked)
    .map(data => data.value);
  if (
    checkedLangs.length === 0 ||
    checkedLangs.length === articleLangs.value.length
  )
    return 'all';
  return checkedLangs[0]; // 中国語('zh')も対応が必要になった場合はここの修正が必要そう
});

const selectedDocTypeForApiParam = computed(() => {
  return docTypes.value
    .filter(docType => docType.isChecked)
    .map(docType => docType.value);
});

const selectedDocTypesForTracking = computed<DocType[]>(() => {
  const checkedLangs = docTypes.value
    .filter(data => data.isChecked)
    .map(data => data.value);
  // 何も選択していない場合はUI上はチェックがついていないが、
  // 計測ログにおいてはデータモデル上全て選択しているのと同じ状態なので、すべての値を返す
  // https://stockmarkteam.slack.com/archives/C05FHRWA342/p1711088626566189?thread_ts=1711074814.753969&cid=C05FHRWA342
  return checkedLangs.length > 0
    ? checkedLangs
    : docTypes.value.map(data => data.value);
});

const clickReset = () => {
  selectedTags.value = [];
  memoFilterItems.value.forEach(item => {
    item.isSelected = false;
  });
  articleLangs.value.forEach(item => {
    item.isChecked = false;
  });
  docTypes.value.forEach(item => {
    item.isChecked = false;
  });
  selectedPestNewsCategories.value = [];

  fetchArticlesWithPaginateReset();
};

const fetchArticlesWithPaginateReset = () => {
  const filterMarkedArticles = async () => {
    if (uId.value === undefined) return;
    await updateSearchKeyword();
  };

  if (pagination.value) {
    pagination.value.removeEvent();
  }
  pagination.value = new Pagination(filterMarkedArticles, LIMIT);
};

const clickApply = (values: {
  tmpSelectedTags: string[];
  tmpMemoFilterItems: FilterSelectBoxItem<MemoFilterValue>[];
  tmpArticleLangs: FilterCheckBoxItem<Lang>[];
  tmpDocTypes: FilterCheckBoxItem<DocType>[];
}) => {
  selectedTags.value = structuredClone(toRaw(values.tmpSelectedTags));
  memoFilterItems.value = structuredClone(toRaw(values.tmpMemoFilterItems));
  articleLangs.value = structuredClone(toRaw(values.tmpArticleLangs));
  docTypes.value = structuredClone(toRaw(values.tmpDocTypes));

  fetchArticlesWithPaginateReset();
};

// 期間指定カレンダー
const startDate = ref<Date | undefined>();
const formattedStartDate = computed<string | undefined>(() => {
  return startDate.value
    ? dayjs(startDate.value).format('YYYY-MM-DD')
    : undefined;
});
const endDate = ref<Date | undefined>();
const formattedEndDate = computed<string | undefined>(() => {
  return endDate.value ? dayjs(endDate.value).format('YYYY-MM-DD') : undefined;
});
const dateRangeKeyForTracking = ref<string | null>(searchPresetActivity[0].key);

const applyDate = async (option: {
  presetKey: string;
  value: { from: SearchDate; to: SearchDate };
}) => {
  const isCustomRange = option.presetKey === 'custom';
  dateRangeKeyForTracking.value = isCustomRange ? null : option.presetKey;
  startDate.value = option.value.from;
  endDate.value = option.value.to;

  fetchArticlesWithPaginateReset();
};

const selectedPestNewsCategories = ref<string[]>([]);

const {
  data: userMarkedArticleCounts,
  state: userMarkedArticleCountsState,
  mutate: userMarkedArticleCountsMutate,
} = useMarkedArticleCountsMypage(
  uId,
  selectedTags,
  selectedMemoExistsTypeForApiParam,
  startDate,
  endDate,
  selectedLangForApiParam,
  selectedDocTypeForApiParam,
  searchQueryForApi,
  selectedPestNewsCategories,
);

const isFetchingMarkedArticleCounts = computed(
  () => userMarkedArticleCountsState.value !== STATES.SUCCESS,
);

const totalMarkedArticleCounts = computed(() => {
  if (isFetchingMarkedArticleCounts.value) return '-';
  return (
    (userMarkedArticleCounts.value?.counts.article || 0) +
    (userMarkedArticleCounts.value?.counts.patent || 0) +
    (userMarkedArticleCounts.value?.counts.research_paper || 0)
  ).toString();
});

const getMarkedArticles = (pageRef: Ref<number>, pageLimit: number) => {
  return useMarkedArticlesMypage(
    pageRef,
    pageLimit,
    uId,
    selectedTags,
    selectedMemoExistsTypeForApiParam,
    startDate,
    endDate,
    selectedLangForApiParam,
    selectedDocTypeForApiParam,
    searchQueryForApi,
    selectedPestNewsCategories,
  );
};

const latestMarksDataAccessor = (
  data: UnwrapRef<ReturnType<typeof useMarkedArticlesMypage>['data']>,
) => data?.marked_articles ?? [];

const hasNextAccessor = (
  data: UnwrapRef<ReturnType<typeof useMarkedArticlesMypage>['data']>,
) => data?.has_next_page ?? false;

const addArticleForArticleList = (article: AdpDocument) => {
  emitter.emit('pagination-item-create', article);
  userMarkedArticleCountsMutate();
};

const removeArticleForArticleList = (article: AdpDocument) => {
  emitter.emit('pagination-item-delete', (items: AdpDocument[]) => {
    const index = items.findIndex(c => c.id === article.id);
    if (index >= 0) {
      items.splice(index, 1);
    }
  });
  userMarkedArticleCountsMutate();
};

const updateArticleForArticleList = (article: AdpDocument) => {
  emitter.emit('pagination-items-update', {
    filterFunc: (item: AdpDocument) => item.id === article.id,
    updateFunc: (articles: AdpDocument[]) => {
      articles.forEach(c => {
        c.memo = article.memo;
        c.tags = article.tags;
      });
    },
  });
};

/**
 * PESTニュースカテゴリー情報フィルター
 */

const { data: fetchNewsCategories } = useMypageNewsCategories();

const articleCountsByCategories = computed<Record<string, number>>(() => {
  if (fetchNewsCategories.value === undefined) return {};

  return fetchNewsCategories.value.marked_articles_news_categories.reduce(
    (acc, cur) => {
      return {
        ...acc,
        [cur.name]: cur.count,
      };
    },
    {},
  );
});

const pestNewsCategoryFilterSections = computed(() => {
  const newsCategoriesByPest = buildNewsCategoriesByPest();

  return pests.map(pest => {
    return {
      sectionName: pestNames[pest],
      options: newsCategoriesByPest[pest]
        .flatMap(category => {
          const count = articleCountsByCategories.value[category] ?? 0;

          if (count === 0) return [];

          return {
            label: `${category}(${count})`,
            value: category,
          };
        })
        .sort((a, b) => {
          return articleCountsByCategories.value[a.value]! >
            articleCountsByCategories.value[b.value]!
            ? -1
            : 1;
        }),
    };
  });
});

const pestNewsCategoryFilterTrackingData = computed(() =>
  pestNewsCategoryFilterSections.value.flatMap(section => {
    const selectedOptions = section.options.filter(option =>
      selectedPestNewsCategories.value.includes(option.value),
    );

    return selectedOptions.length > 0
      ? [
          {
            name: section.sectionName,
            items: selectedOptions.map(option => ({ name: option.value })),
          },
        ]
      : [];
  }),
);

const clickApplyPestNewsCategoryFilter = async (
  tmpPestNewsCategories: string[],
) => {
  selectedPestNewsCategories.value = structuredClone(
    toRaw(tmpPestNewsCategories),
  );
  fetchArticlesWithPaginateReset();
};

const isUserMarkSurveyEnabled = featureFlags.ANDEV_5481_USER_MARK_SURVEY;

const navigateToSurvey = () => {
  router.push({
    name: 'surveyTop',
    query: { mode: 'user_marks', from: 'my_marks_page' },
  });
};
</script>

<template>
  <div v-if="targetUserInfo.id" class="o-my-activity-feed">
    <Header
      :title="targetUserInfo.user_name"
      :detail="`${targetUserInfo.email} ${mypageAccessScope}`"
      :avatar-image-url="targetUserInfo.image_url"
      :tabs="getTabs()"
      :organization-tag-list="userOrganizationTags?.organization_tags"
    >
      <template #button v-if="isUserMarkSurveyEnabled">
        <div>
          <button
            class="survey-button c-btn c-btn--primary"
            @click.prevent="() => navigateToSurvey()"
          >
            <DgrIcon size="small" name="sparkles-fill" :keep-fill="false" />
            マークした情報から要約生成
          </button>
        </div>
      </template>
    </Header>
    <Content>
      <template v-if="isMypageRestricted">
        <div class="page-content">
          <div class="restrict-disclosure">
            <DgrIcon size="xlarge" name="lock" />{{ targetUserInfo.user_name }}
            さんはページの公開範囲を制限しています。
          </div>
        </div>
      </template>
      <template v-else>
        <div class="page-content">
          <div>
            <span class="c-title c-title--m"
              >マーク<span class="c-text c-text--s"
                >・{{ totalMarkedArticleCounts }} 件</span
              ></span
            >
            <div class="spacing-24"></div>
            <SearchBar
              class="search-bar"
              :placeholder="'記事を検索'"
              @on-change-query="handleChangeQuery"
              @on-search-query="fetchArticlesWithPaginateReset()"
            ></SearchBar>
            <div class="spacing-16"></div>
            <div class="action-bar">
              <div class="left-box">
                <ActivitiesFilter
                  :is-group-page="false"
                  :article-tag-list="articleTagList"
                  :selected-tags="selectedTags"
                  :memo-filter-items="memoFilterItems"
                  :article-langs="articleLangs"
                  :doc-types="docTypes"
                  @click-reset="clickReset"
                  @click-apply="clickApply"
                />
                <div class="spacing-16"></div>
                <DropdownDateRangeCalendar
                  :range="{
                    from: startDate,
                    to: endDate,
                  }"
                  :preset="searchPresetActivity"
                  :min="FIRST_PUBLISHED_AT"
                  :max="getToday()"
                  :is-open="true"
                  :disabled="false"
                  :font-size="'small'"
                  :icon-size="'small'"
                  @apply-date="applyDate"
                />
                <div class="spacing-16"></div>
                <ActivitiesSectionedCheckboxFilter
                  :initial-form-value="selectedPestNewsCategories"
                  :sections="pestNewsCategoryFilterSections"
                  @submit="clickApplyPestNewsCategoryFilter"
                />
              </div>
            </div>
          </div>
          <SelectedTagList
            v-if="targetTags.length > 0"
            class="selected-tags"
            label="選択中のマイタグ"
            :selected-tags="targetTags"
            :is-editing="true"
            :show-tooltip="true"
            @delete-tag="removeTag"
          ></SelectedTagList>
          <div class="spacing-16"></div>
          <div class="mark-page-content">
            <div class="o-feed">
              <div
                class="o-no-user-marks"
                v-if="totalMarkedArticleCounts === '0'"
              >
                <div class="o-description c-text c-text--m">
                  該当する記事はありませんでした。
                </div>
                <img src="@/assets/state-empty-article.png" />
              </div>
              <SwrScrollingPagination
                :key="`${[
                  uId,
                  selectedTags,
                  selectedMemoExistsTypeForApiParam,
                  startDate,
                  endDate,
                  selectedLangForApiParam,
                  selectedDocTypeForApiParam,
                  searchQueryForApi,
                  selectedPestNewsCategories,
                ]}`"
                :page-limit="LIMIT"
                :data-accessor="latestMarksDataAccessor"
                :has-next-accessor="hasNextAccessor"
                :pagination-func="getMarkedArticles"
                :scroll-target="'window'"
                @fetch="itemsFetched"
              >
                <template v-slot="{ items: articles, loaded }">
                  <div
                    class="o-article-card"
                    v-for="articleItem in articles"
                    :key="articleItem.id"
                    @click="clickArticle($event, articleItem)"
                  >
                    <AdpDocumentCard
                      :is-selected="articleSelected(articleItem.id)"
                      :enable-hover="articleHovered(articleItem.id)"
                      :page-name="PAGE_NAME"
                      :adp-document="articleItem"
                      feature="my_marks"
                      :theme-id="themeId(articleItem)"
                      :show-comment="false"
                      :priority-display-tags="selectedTags"
                      :show-tag-list="true"
                      :contents-context="contentsContext"
                    ></AdpDocumentCard>
                  </div>
                  <div class="loading-icon" v-if="!loaded">
                    <DgrLoading />
                  </div>
                </template>
              </SwrScrollingPagination>
            </div>
            <div class="content-right">
              <div class="content-right-body">
                <AdpDocumentInfo
                  class="article-info"
                  v-if="selectedArticle"
                  :adp-document="selectedArticle"
                  :tag-list="articleTags"
                  :editable="true"
                  @article-info-updated="updateArticle"
                  :page-name="PAGE_NAME"
                  :has-document-export="true"
                  :is-group-view="false"
                ></AdpDocumentInfo>
                <AdpDocumentInfoDefault
                  v-else
                  :adp-document="selectedArticles"
                  :tag-list="articleTags"
                  :page-name="PAGE_NAME"
                  :has-document-export="true"
                >
                </AdpDocumentInfoDefault>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Content>
  </div>
</template>
<style lang="scss" scoped>
.o-my-activity-feed {
  width: 100%;
  margin: -24px 0 0 0;

  .survey-button {
    width: 234px;
    margin-left: 48px;

    .icon-box {
      fill: #fff;
      margin-right: 4px;
    }
  }

  .page-content {
    display: flex;
    flex-direction: column;
  }

  .mark-page-content {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 16px;
    justify-content: start;
  }

  .c-text--s {
    color: $color-gray600;
  }

  .search-bar {
    width: 616px;
  }

  .action-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 920px;
    height: 32px;
    margin-bottom: 16px;

    .left-box {
      display: flex;
      align-items: center;
    }

    .c-text--s {
      color: $color-gray600;
    }

    .tag-box {
      .tag-selectbox {
        width: auto;
      }
    }
  }

  .selected-tags {
    width: 920px;
  }

  .o-feed {
    margin-top: 0px;
    width: 616px;

    .articles-empty {
      width: 592px;
      height: 54px;
      background: #ffffff;
      border: 1px solid #e6e6e6;
      box-sizing: border-box;
      border-radius: 4px;

      .articles-empty-content {
        padding: 16px 0 16px 16px;
      }
    }
  }

  .article-info {
    background: #ffffff;
  }

  .o-no-user-marks {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 616px;
    height: 212px;
    background: #ffffff;
    border: 1px solid #e6e6e6;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0 32px 0 48px;
    img {
      height: 200px;
      width: 200px;
    }
  }
  .o-article-card {
    margin-bottom: 10px;
    cursor: pointer;
  }
  .article-selected {
    border: 1px solid #1da482;
  }

  .tag-selectbox :deep(.selector-popup) {
    display: none;
  }

  .article-hovered {
    &:hover {
      border-color: #b3b3b3;
    }
  }

  .content-right {
    position: sticky;
    // header(64px) + tab(40px) + 余白(16px)を考慮した数値
    top: 120px;
    width: 292px;
    max-height: 80vh;
    overflow-y: auto;
    .content-right-body {
      width: 100%;
    }
  }

  .filters {
    display: flex;
  }
}

.export-button {
  width: 200px;
}

.restrict-disclosure {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 616px;
  height: 212px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0 32px 0 48px;
}
.loading-icon {
  display: flex;
  justify-content: center;
}
</style>
