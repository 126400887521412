import { LocationQueryValue } from 'vue-router';
import { DocType, FileNameSearchResponse } from '@/types';

export const getDocTypesQueryParam = (
  docTypes: LocationQueryValue | Array<LocationQueryValue>,
): Array<DocType | 'user_document'> | [] => {
  if (!docTypes) return [];

  const docTypesQueryParam = Array.isArray(docTypes) ? docTypes : [docTypes];
  return docTypesQueryParam as Array<DocType | 'user_document'>;
};

export const getSpecifiedFilesQueryParam = (
  specifiedFiles: LocationQueryValue | Array<LocationQueryValue>,
): Array<FileNameSearchResponse> | [] => {
  if (!specifiedFiles) return [];

  const specifiedFilesQueryParam = (specifiedFiles as string[])?.map(file =>
    JSON.parse(file),
  ) as Array<FileNameSearchResponse>;
  return specifiedFilesQueryParam;
};

/**
 * スネークケース(バックエンドの命名規則) を キャメルケース(フロントエンドの命名規則) に変換する関数
 * @param str スネークケースの文字列
 * @returns キャメルケースの文字列
 */
const toCamelCase = (str: string): string => {
  return str.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
};

/**
 * オブジェクトのプロパティ名をキャメルケースに変換する関数
 * @param obj スネークケースのプロパティを持つオブジェクト
 * @param recursive 再帰的に変換するかどうかのフラグ
 * @returns キャメルケースのプロパティを持つオブジェクト
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const convertKeysToCamelCase = (obj: any, recursive = true): any => {
  if (Array.isArray(obj)) {
    return obj.map(item => convertKeysToCamelCase(item, recursive));
  } else if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce((acc, key) => {
      const camelCaseKey = toCamelCase(key);
      acc[camelCaseKey] = recursive
        ? convertKeysToCamelCase(obj[key], recursive)
        : obj[key];
      return acc;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }, {} as any);
  }
  return obj;
};
