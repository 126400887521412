<script setup lang="ts">
import { ButtonHTMLAttributes, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import api from '@/api';
import { DgrIcon, DgrPopover, IconTypes } from '@stockmarkteam/donguri-ui';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import Item from '@/components/menu/common/item.vue';
import SectionWrapper from '@/components/menu/sections/section-wrapper.vue';
import { SurveySessionResponse } from '@/types/index';
import { useEmitter, useStore } from '@/utils/vue';

type Action = {
  name: string;
  func: (surveySessionId: string, title: string) => void;
  icon: IconTypes;
  isSeparator?: boolean;
};

interface ExtendedTriggerProps extends ButtonHTMLAttributes {
  'data-state'?: string;
}

const emitter = useEmitter();
const route = useRoute();
const router = useRouter();
const store = useStore();
const { createSnackbar } = useSnackbar();

const PAGE_NUMBER = 1;
const SURVEYS_LIMIT = 100;
const ACTION_BUTTON_Z_INDEX = 15; // サイドメニューの下に隠れないように`--z-left-menu`より1つ上の値にする
const surveys = ref<Array<SurveySessionResponse>>([]);
const hoveredIndex = ref(-1);

const actions: Array<Action> = [
  {
    name: '削除',
    func: deleteHandler,
    icon: 'trash',
  },
];

/**
 * スレッド (surveys) の一覧を取得
 *
 * 2024-11-14現在 スレッドの上限は100件 かつ 取得するデータ項目もtitle, updated_at, uuidの3つと少ないため、ページング処理は不要 (考慮事項が増えるので、必要になるまで実装しない)
 * ※ APIとしてはページング処理ができるようになっている
 */
const fetchSurveys = async () => {
  surveys.value = await api.fetchSurveys(PAGE_NUMBER, SURVEYS_LIMIT);
};

const deleteSurveySession = async (surveySessionId: string, title: string) => {
  try {
    const currentSurveySessionId = route.params.survey_session_id as string;
    await api.deleteSurveySession(surveySessionId);
    await fetchSurveys();
    if (currentSurveySessionId === surveySessionId)
      router.push({ name: 'surveyTop' });

    createSnackbar({
      message: `「${title}」を削除しました`,
      type: 'success',
    });
  } catch (_e) {
    createSnackbar({
      message: `「${title}」を削除できませんでした`,
      type: 'error',
    });

    throw new Error(`要約のスレッドを削除できませんでした: ${_e}`);
  }
};

function deleteHandler(surveySessionId: string, title: string) {
  const payload = {
    headerText: '履歴の削除',
    bodyText: `「${title}」を削除しますか？`,
    action: async () => await deleteSurveySession(surveySessionId, title),
    btnText: '削除',
    cancelBtnText: 'キャンセル',
    isBtnDisabled: false,
  };
  store.commit('confirmationModal/showConfirmationModal');
  store.commit('confirmationModal/setTextAndAction', payload);
}

onMounted(async () => {
  await fetchSurveys();

  // 要約メニューで 「新規スレッド作成」 or 「既存スレッド更新」 が行われた場合、スレッド一覧を更新する
  emitter.on(
    'update-survey-session',
    async (updatedSurvey: SurveySessionResponse) => {
      const existingIndex = surveys.value.findIndex(
        survey => survey.session_id === updatedSurvey.session_id,
      );

      if (existingIndex !== 0) await fetchSurveys();
    },
  );
});
</script>
<template>
  <SectionWrapper>
    <Item :id="'surveyTop'" :name="'トップ'" :route="{ name: 'surveyTop' }" />
    <div class="item-list">
      <p class="list-title c-title c-title--s">履歴</p>
      <div class="items">
        <ul class="list-style--none">
          <li :key="survey.session_id" v-for="(survey, idx) in surveys">
            <router-link
              :to="{
                name: 'surveySession',
                params: { survey_session_id: survey.session_id },
              }"
              custom
              v-slot="{ href, isActive, isExactActive }"
            >
              <a :href="href">
                <div
                  class="item c-text c-text--m"
                  :class="{
                    selected: isActive || isExactActive,
                  }"
                  @mouseover="hoveredIndex = idx"
                  @mouseleave="hoveredIndex = -1"
                >
                  <span class="item-title">{{ survey.title }}</span>
                  <DgrPopover
                    :id="survey.session_id"
                    :z-index="ACTION_BUTTON_Z_INDEX"
                    placement="bottom-end"
                  >
                    <template #default="{ triggerProps }">
                      <button
                        v-if="
                          hoveredIndex === idx ||
                          (triggerProps as ExtendedTriggerProps)[
                            'data-state'
                          ] === 'open'
                        "
                        v-bind="triggerProps"
                        type="button"
                        class="unstyled-button open-popover-button"
                        @click.prevent.stop=""
                      >
                        <DgrIcon name="ellipsis-h" size="xs" />
                      </button>
                      <span v-else></span>
                    </template>
                    <template #content="{ close }">
                      <div class="actions-container">
                        <template v-for="action in actions" :key="action.name">
                          <div
                            class="separator"
                            v-if="action.isSeparator"
                          ></div>
                          <button
                            class="action-button unstyled-button c-text c-text--m"
                            @click="
                              action.func(survey.session_id, survey.title);
                              close();
                            "
                          >
                            <DgrIcon
                              size="small"
                              :name="action.icon"
                              class="icon small"
                              v-if="action.icon"
                            />{{ action.name }}
                          </button>
                        </template>
                      </div>
                    </template>
                  </DgrPopover>
                </div>
              </a>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </SectionWrapper>
</template>
<style scoped lang="scss">
// SectionWrapperコンポーネントのスタイルを上書き
.section-wrapper {
  gap: 16px;
}

.list-title {
  padding: 4px;
}

.list-style--none {
  list-style: none;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 240px;
  height: 40px;
  border-radius: 4px;
  padding: 8px;
  background: #fff;
  cursor: pointer;

  &:hover {
    background: $color-gray200;
  }

  &.selected {
    color: $color-green600;
    background: rgba(152, 208, 188, 0.2);

    &:hover {
      background: rgba(152, 208, 188, 0.2);
    }
  }

  .item-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
    min-width: 0;
  }
}

// button要素のデフォルトスタイルをリセットするクラス
.unstyled-button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  flex-shrink: 0;
}

.open-popover-button {
  margin: 0 8px 0 16px;
}

.actions-container {
  width: 100%;
  margin: 12px 0;

  .action-button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // width: fit-content;
    padding: 5px 12px;
    height: 30px;

    &:hover {
      background: $color-gray400;
    }
    .icon {
      margin-right: 4px;
    }
  }

  .separator {
    margin: 8px 0;
    border-top: 1px solid $color-gray400;
  }
}
</style>
