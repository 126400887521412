<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import api from '@/api';
import { PromisePool } from '@supercharge/promise-pool';
import { CanceledError } from 'axios';
import JSZip from 'jszip';
import AnewsStorageUploadProgressModal from '@/components/admin/user-documents/anews-storage-upload-progress-modal.vue';
import FileSelector from '@/components/common/file-selector.vue';
import AdminContent from '@/components/layouts/admin-content.vue';
import Header from '@/components/layouts/header.vue';
import { checkMoveToAdmin } from '@/utils/user';
import { StorageStatus } from '@/utils/user-document/types';

type FileValidationStatus =
  | 'UNCHECKED'
  | 'OK'
  | 'FILE_INVALID'
  | 'UNZIP_FAILED'
  | 'FILE_COUNT_INVALID'
  | 'FILE_SIZE_INVALID';

const MAX_ZIP_FILE_COUNT = 3000;
const MAX_FILE_SIZE_GB = 1;
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_GB * 1024 * 1024 * 1024;
const FILE_PART_SIZE = 50 * 1024 * 1024;
const UPLOAD_CONCURRENCY_LIMIT = 10;
const MAX_RETRY_NUMBER = 2;

const route = useRoute();
const router = useRouter();
const storageStatus: StorageStatus = route.query.storageStatus as StorageStatus;
const uploadController = ref(new AbortController());

const fileSelectorRef = ref<InstanceType<typeof FileSelector>>();
const uploadTotal = ref<number>(0);
// 各パートのアップロード済みサイズ
const uploadPartLoaded = ref<{ [index: number]: number }>({});
const uploadProgress = computed<number>(() => {
  const allLoaded = Object.values(uploadPartLoaded.value).reduce(
    (acc, cur) => acc + cur,
    0,
  );
  return Math.floor((allLoaded / uploadTotal.value) * 100) || 0;
});
const fileValidationStatus = ref<FileValidationStatus>('UNCHECKED');
const isUploadError = ref<boolean>(false);
const isProgressModalOpen = ref<boolean>(false);

const isReUpload = computed<boolean>(() =>
  ['SYNCING', 'SYNCED', 'SYNC_FAILED'].includes(storageStatus),
);
const isSyncing = computed<boolean>(() => storageStatus === 'SYNCING');
const isReadyToUpload = computed<boolean>(
  () => fileValidationStatus.value === 'OK' && !isSyncing.value,
);
const displayFileError = computed<string>(() => {
  switch (fileValidationStatus.value) {
    case 'UNZIP_FAILED':
      return 'このファイルを解凍できませんでした。ファイルを確認して再度ファイルを選択してください。';
    case 'FILE_COUNT_INVALID':
      return `アップロード上限数の${MAX_ZIP_FILE_COUNT}ファイルを超えているためアップロードできません。ファイル数を減らして再度ファイルを選択してください。`;
    case 'FILE_INVALID':
      return 'このファイルはアップロードできません。ファイルを確認して再度ファイルを選択してください。';
    case 'FILE_SIZE_INVALID':
      return `アップロード上限容量の${MAX_FILE_SIZE_GB}GBを超えているためアップロードできません。ファイル総容量を減らして再度ファイルを選択してください。`;
    case 'UNCHECKED':
    case 'OK':
    default:
      return '';
  }
});

onMounted(async () => {
  checkMoveToAdmin(router);
});

const stopUpload = async () => {
  uploadController.value.abort();
};

const validateFile: (file: File) => Promise<FileValidationStatus> = async (
  file: File,
) => {
  // 拡張子チェック
  if (!file.name.endsWith('.zip')) return 'FILE_INVALID';

  // ZIPファイルの中身チェック
  try {
    const loadedZip = await JSZip.loadAsync(file);
    const allFiles = loadedZip.filter((_, file) => !file.dir);
    const fileCount = allFiles.length;
    if (fileCount > MAX_ZIP_FILE_COUNT) return 'FILE_COUNT_INVALID';

    // 圧縮前の総サイズ
    const fileInZipSizes = await Promise.all(
      allFiles.map(async file => (await file.async('uint8array')).length),
    );
    const uncompressedSize = fileInZipSizes.reduce((acc, cur) => acc + cur, 0);
    if (uncompressedSize > MAX_FILE_SIZE_BYTES) return 'FILE_SIZE_INVALID';

    return 'OK';
  } catch {
    return 'UNZIP_FAILED';
  }
};

const onFileChange = async (file: File | null) => {
  fileValidationStatus.value = 'UNCHECKED';
  if (!file) return;
  fileValidationStatus.value = await validateFile(file);
};

const backToAdmin = () => {
  router.push({ name: 'userDocumentsAdmin' });
};

// ファイル分割。参考: https://github.com/t-kigi/s3-multipart-uploads-with-chalice/blob/main/js/upload.js
const readPartData = async (file: File, index: number) => {
  return new Promise<Uint8Array>((resolve, _reject) => {
    const reader = new FileReader();
    const offset = index * FILE_PART_SIZE;
    const slice = file.slice(offset, offset + FILE_PART_SIZE, file.type);
    reader.readAsArrayBuffer(slice);

    reader.onload = e => {
      const data = new Uint8Array(e.target?.result as ArrayBuffer);
      resolve(data);
      reader.abort();
    };
  });
};

const range = (start: number, end: number) =>
  Array.from({ length: end - start + 1 }, (_v, k) => k + start);

// リトライ処理
const retryPromise = async <T,>(
  maxRetryNumber: number,
  action: () => Promise<T>,
  shouldRetry: (e: unknown) => boolean = () => true,
): Promise<T | undefined> => {
  for (let retryCount = 0; retryCount <= maxRetryNumber; retryCount++) {
    try {
      return await action();
    } catch (e) {
      if (!shouldRetry(e) || retryCount >= maxRetryNumber) throw e;
    }
  }
};

// ファイルパートの並行アップロード
const uploadFileParts = async (urls: string[], fileParts: Uint8Array[]) => {
  const onProcess = async (partData: Uint8Array, index: number) => {
    const onUploadProgress = (loaded: number) => {
      uploadPartLoaded.value[index] = loaded;
    };
    const uploadTask = async () => {
      await api.putAnewsStorageUpload(
        urls[index],
        partData,
        onUploadProgress,
        uploadController.value.signal,
      );
    };
    const shouldRetry = (e: unknown) => !(e instanceof CanceledError);
    await retryPromise(MAX_RETRY_NUMBER, uploadTask, shouldRetry);
  };
  const onError = (e: unknown) => {
    stopUpload();
    throw e;
  };

  await new PromisePool()
    .withConcurrency(UPLOAD_CONCURRENCY_LIMIT)
    .for(fileParts)
    .handleError(onError)
    .process(onProcess);
};

// 進捗モーダルが閉じられたらアップロードを中止
watch(isProgressModalOpen, newValue => {
  if (!newValue) {
    stopUpload();
  }
});

// ファイルのアップロード処理
const uploadFile = async () => {
  const file = fileSelectorRef.value?.file;
  if (!file) return;

  isUploadError.value = false;
  isProgressModalOpen.value = true;
  uploadController.value = new AbortController();
  uploadTotal.value = file.size;
  uploadPartLoaded.value = {};

  try {
    // データソース作成
    if (!isReUpload.value) {
      await api.postTeamAnewsStorageDatasource();
    }

    // ファイル分割
    const partCount = Math.ceil(file.size / FILE_PART_SIZE);
    const fileParts = await Promise.all(
      range(0, partCount - 1).map(index => readPartData(file, index)),
    );

    // 進捗の初期化
    uploadPartLoaded.value = Array.from({ length: partCount }, () => 0);

    // 署名付きURL発行依頼
    const {
      urls,
      upload_id: uploadId,
      upload_file_key: uploadFileKey,
    } = await api.getAnewsStorageUploadUrls(partCount);
    // ファイルアップロード
    await uploadFileParts(urls, fileParts);

    // アップロード完了通知
    await api.postAnewsStorageUploadComplete(uploadFileKey, uploadId);

    // 管理画面に遷移
    backToAdmin();
  } catch {
    isUploadError.value = true;
  }
};
</script>

<template>
  <div class="user-documents-admin">
    <Header title="社内情報ストレージ管理" header-width="100%"></Header>
    <AdminContent>
      <div class="title">
        <span class="c-title c-title--xm" v-if="isReUpload">ファイル更新</span>
        <span class="c-title c-title--xm" v-else>ファイルをアップロード</span>
      </div>
      <div class="spacing-16"></div>
      <div class="anews-storage-upload-form-content">
        <div v-if="isReUpload" class="c-text c-text--m re-upload-text">
          ファイル更新を行うと現在アップロードされている全てのファイルが削除され、新しくアップロードしたファイルに置き換わります。
        </div>
        <div class="file-selector">
          <FileSelector
            ref="fileSelectorRef"
            input-accept=".zip"
            :on-file-change="onFileChange"
            button-text="ZIPファイルを選択"
            :button-hint-text="`アップロード可能なZIPファイル内のファイル数は最大${MAX_ZIP_FILE_COUNT}・ファイル総容量は最大${MAX_FILE_SIZE_GB}GBです`"
            :file-hint-text="
              fileValidationStatus === 'UNCHECKED'
                ? 'ファイルを確認しています...'
                : undefined
            "
            :file-error-text="displayFileError"
          />
        </div>

        <div v-if="isSyncing" class="c-text c-text--m is-syncing-error-text">
          同期処理中のファイルがあるためこの操作を実行できません。同期完了後に再度行ってください。
        </div>

        <div class="button-wrapper">
          <button
            @click="backToAdmin"
            class="c-outlineBtn c-btn--auto c-outlineBtn--secondary"
          >
            キャンセル
          </button>
          <button
            @click="uploadFile"
            class="c-btn c-btn--auto c-btn--AnewsPrimary"
            :class="{ disabled: !isReadyToUpload }"
            :disabled="!isReadyToUpload"
          >
            アップロード
          </button>
        </div>
      </div>
      <AnewsStorageUploadProgressModal
        v-model:is-open="isProgressModalOpen"
        :progress="uploadProgress"
        :error-text="
          isUploadError
            ? 'アップロードに失敗しました。しばらくしてからやり直してください。'
            : undefined
        "
      />
    </AdminContent>
  </div>
</template>

<style lang="scss" scoped>
.user-documents-admin {
  width: 100%;
  margin: -24px 0 0 0;
  padding: 0 !important;

  .anews-storage-upload-form-content {
    min-width: 360px;
    padding: 8px 24px 16px 24px;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid $color-border;
  }

  .re-upload-text {
    margin: 8px 0;
  }

  .file-selector {
    margin: 8px 0;
  }

  .is-syncing-error-text {
    padding: 12px 0;
    color: $color-orange1000;
  }

  .button-wrapper {
    display: flex;
    justify-content: flex-end;
    padding-top: 9px;
    gap: 0 8px;
  }
}
</style>
