import { RouteLocationNormalizedLoaded } from 'vue-router';
import axios, { isAxiosError } from 'axios';
import dayjs from 'dayjs';
import Qs from 'qs';
import type {
  Article,
  DocType,
  ExportFormatType,
  ExportGroupContentType,
  Group,
  GroupActivityProps,
  GroupTag,
  GroupUser,
  Lang,
  MSTeamsSettings,
} from '@/types';
import { ActionDataResponse } from '@/utils/downloadActionData';
import { userSession } from '@/utils/userSession';

export async function fetchGroupMarks(
  groupId: number | undefined,
  pageRef: number,
  pageLimit = 1,
  tags: string[],
  abortController: AbortController,
  memoExists?: boolean,
  fromDate?: Date,
  toDate?: Date,
  lang?: Lang[],
  docTypes?: DocType[],
  query?: string,
  newsCategories?: string[],
): Promise<{
  articles: Article[];
  trackingSessionId?: string;
}> {
  if (groupId === undefined) return { articles: [] };

  const params = {
    page: pageRef,
    limit: pageLimit,
    order: 'desc',
    memo_exists: memoExists,
    include_technical_literature: true,
    tags: tags.length > 0 ? tags : undefined,
    filter_target_group_comment: true,
    from_date: fromDate ? dayjs(fromDate).format('YYYY-MM-DD') : undefined,
    to_date: toDate ? dayjs(toDate).format('YYYY-MM-DD') : undefined,
    lang: lang,
    doc_types: docTypes,
    query: query,
    news_categories: newsCategories,
  };
  try {
    const { data } = await axios.get<{
      marked_articles: Article[];
      tracking_session_id: string;
    }>(
      `/groups/${groupId}/marked_articles?${Qs.stringify(params, {
        arrayFormat: 'brackets',
      })}`,
      { signal: abortController.signal },
    );
    return {
      articles: data.marked_articles,
      trackingSessionId: data.tracking_session_id,
    };
  } catch (e) {
    if (axios.isCancel(e)) return { articles: [] };
    throw e;
  }
}

export async function updateGroupMSTeamsSettings(
  groupId: number,
  settings: MSTeamsSettings,
): Promise<void> {
  await axios.put<void>(`/groups/${groupId}/ms_teams_settings`, settings);
}

export async function fetchGroupMSTeamsSettings(
  groupId: number,
): Promise<MSTeamsSettings> {
  const { data } = await axios.get<MSTeamsSettings>(
    `/groups/${groupId}/ms_teams_settings`,
  );
  return data;
}

export async function createGroup(
  name: string,
  description: string,
): Promise<void> {
  await axios.post<void>(`/groups`, { name, description });
}

export async function fetchGroup(groupId: number): Promise<Group> {
  const { data } = await axios.get<Group>(`/groups/${groupId}`);
  return data;
}

export async function updateGroup(
  groupId: number,
  name: string,
  description: string,
  enabledOverlookedMail: boolean,
): Promise<void> {
  await axios.put<void>(`/groups/${groupId}`, {
    name,
    description,
    enabled_overlooked_mail: enabledOverlookedMail,
  });
}

export async function deleteGroup(groupId: number): Promise<void> {
  await axios.delete<void>(`/groups/${groupId}`);
}

export async function fetchGroupMembers(groupId: number): Promise<GroupUser[]> {
  const { data } = await axios.get<{ group_users: GroupUser[] }>(
    `/groups/${groupId}/users`,
  );
  return data.group_users;
}

export async function joinGroup(groupId: number): Promise<void> {
  const myUserId = userSession.getUserId();
  await axios.post<void>(`/groups/${groupId}/users/${myUserId}`);
}

export async function leaveGroup(
  groupId: number,
  userId?: number,
): Promise<void> {
  const myUserId = userId ?? userSession.getUserId();
  await axios.delete<void>(`/groups/${groupId}/users/${myUserId}`);
}

export async function createGroupInvitation(
  groupId: number,
  userIds: number[],
): Promise<void> {
  await axios.post<void>(`/groups/${groupId}/invitations`, {
    user_ids: userIds,
  });
}

export async function updateGroupArticleMemo(
  groupId: number,
  articleId: number,
  docType: DocType,
  memo: string,
  articleLang: Lang,
): Promise<void> {
  await axios.put<void>(`/groups/${groupId}/articles/${articleId}/memos`, {
    content: memo,
    doc_type: docType ?? 'article',
    article_lang: articleLang,
  });
}

export async function deleteGroupArticleMemo(
  groupId: number,
  articleId: number,
  docType: DocType,
  articleLang: Lang,
): Promise<void> {
  const params = new URLSearchParams({
    doc_type: docType,
    article_lang: articleLang,
  }).toString();
  await axios.delete<void>(
    `/groups/${groupId}/articles/${articleId}/memos?${params}`,
  );
}

export async function updateGroupArticleTags(
  groupId: number,
  articleId: number,
  docType: DocType,
  tags: string[],
  articleLang: Lang,
): Promise<void> {
  await axios.put<void>(`/groups/${groupId}/articles/${articleId}/tags`, {
    tags: tags,
    doc_type: docType ?? 'article',
    article_lang: articleLang,
  });
}

export async function createGroupTag(
  groupId: number,
  tagName: string,
  articleId?: number,
): Promise<GroupTag> {
  const { data } = await axios.post<GroupTag>(`/groups/${groupId}/group_tag`, {
    article_id: articleId,
    tag_name: tagName,
  });
  return data;
}
export async function editGroupTag(
  groupTagId: number,
  tagName: string,
): Promise<GroupTag> {
  const { data } = await axios.put<GroupTag>(
    `/groups/group_tag/${groupTagId}`,
    {
      tag_name: tagName,
    },
  );
  return data;
}

export async function deleteGroupTag(groupTagId: number): Promise<void> {
  await axios.delete<void>(`/groups/group_tag/${groupTagId}`);
}

export async function fetchGroupActivityDownload(
  props: GroupActivityProps,
  option: {
    selectedTypes: ExportGroupContentType[];
    exportType: ExportFormatType;
  },
  route: RouteLocationNormalizedLoaded,
): Promise<{ data?: ActionDataResponse; error?: Error }> {
  const period = {
    start: props.startDate,
    end: props.endDate,
  };

  const exportUrl = `/groups/${props.groupId}/action_data?${Qs.stringify(
    {
      tags: props.selectedTags,
      exists_memo: props.selectedMemoExistsTypeForApi,
      lang:
        props.selectedLangsForTracking === 'all'
          ? undefined
          : props.selectedLangsForTracking,
      doc_types: props.selectedDocTypesForApi,
      from: period.start,
      to: period.end,
      query: props.searchQuery !== '' ? props.searchQuery : undefined,
      news_categories: props.selectedPestNewsCategories,
      types: option.selectedTypes,
      tracking_data: {
        page: {
          url: route.fullPath,
          name: 'group_activity_download',
        },
        format: option.exportType,
        period,
        request: {
          sort: props.sortType,
          tags: props.selectedTags,
          // NOTE: メモがあるかないかのフィルター選択値を計測ログでは'article_type' として使用している （経緯は不明）
          article_type: props.memoFilterLabel,
          show_comment: props.commentToggleLabel,
          lang: props.selectedLangsForTracking,
          query: props.searchQuery,
          data_sources: props.selectedDocTypesForTracking,
          period,
        },
        feature: 'group_activity_download',
      },
    },
    { arrayFormat: 'brackets' },
  )}`;
  try {
    const { data } = await axios.get<ActionDataResponse>(exportUrl);
    return { data };
  } catch (err) {
    if (isAxiosError(err)) {
      if (
        err.response?.status === 400 &&
        err.response.data.type === 'result_too_large'
      ) {
        return {
          error: new Error('largeError'),
        };
      }
    }
    return {
      error: new Error('error'),
    };
  }
}
