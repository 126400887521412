<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { DgrIcon } from '@stockmarkteam/donguri-ui';
import { FileNameSearchResponse, IdentifyValue, TagType } from '@/types';
import { useUserDocumentActionHistories } from '@/utils/composables/useUserDocumentActionHistories';
import {
  getDocumentUrl,
  getFileTypeIconName,
} from '@/utils/user-document/common';
import { userSession } from '@/utils/userSession';

interface Props {
  type: TagType;
  tagLabel: string;
  enableDelete: boolean; // 入力欄に表示するタグの場合は、×ボタンで削除が可能
  identifyValue: IdentifyValue; // 親コンポーネントにemitした後にhandlingで使用 (tagLabelだけだと'マイページしたマーク'などは元のvalueを特定するのが面倒)
  file?: FileNameSearchResponse;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  'delete-tag': [value: { filterType: TagType; identifyValue: IdentifyValue }];
}>();

const { createUserDocumentViewHistories } = useUserDocumentActionHistories(
  userSession.getUserId(),
);
const fileNameRef = ref<HTMLElement | null>(null);
const isFileNameOverflowing = ref(false);
const fileUrl = computed(() => (props.file ? getDocumentUrl(props.file) : ''));

const checkOverflow = (element: HTMLElement | null) => {
  if (element) {
    return element.scrollWidth > element.clientWidth;
  }
  return false;
};

const removeTag = () => {
  emit('delete-tag', {
    filterType: props.type,
    identifyValue: props.identifyValue,
  });
};

const createUserDocumentViewHistory = async () => {
  await createUserDocumentViewHistories(props.file?.id ?? '');
};

// テキストが横幅に収まらずに省略されている場合はツールチップで全文を表示する
onMounted(() => {
  isFileNameOverflowing.value = checkOverflow(fileNameRef.value);
});
</script>

<template>
  <div class="tag-container">
    <template v-if="props.type === 'docTypes'">
      <span class="tag-label">{{ props.tagLabel }}</span>
      <DgrIcon
        v-if="props.enableDelete"
        @click="removeTag()"
        class="delete-icon"
        name="times"
        size="xs"
      />
    </template>
    <template v-if="props.type === 'specifiedFiles' && props.file">
      <DgrIcon
        :name="getFileTypeIconName(props.file.file_type)"
        size="small"
        class="file-type-icon"
      />
      <VTooltip
        v-if="isFileNameOverflowing"
        placement="top"
        :popper-triggers="['hover']"
        :delay="{ show: 0, hide: 200 }"
        class="tag-label"
      >
        <a
          @click="createUserDocumentViewHistory()"
          :href="fileUrl"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ props.file.filename }}
        </a>
        <template #popper>
          <a
            @click="createUserDocumentViewHistory()"
            :href="fileUrl"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ props.file.filename }}
          </a>
        </template>
      </VTooltip>
      <span v-else ref="fileNameRef" class="tag-label">
        <a
          @click="createUserDocumentViewHistory()"
          :href="fileUrl"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ props.file.filename }}
        </a>
      </span>
      <DgrIcon
        v-if="props.enableDelete"
        @click="removeTag()"
        class="delete-icon"
        name="times"
        size="xs"
      />
    </template>
  </div>
</template>

<style lang="scss" scoped>
.tag-container {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  border-radius: 100px;
  border: solid 1px $color-gray400;
  padding: 5px 12px;
  max-width: 232px;
}

.tag-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  a {
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.delete-icon {
  cursor: pointer;
  flex-shrink: 0;
}

.file-type-icon {
  flex-shrink: 0;
}
</style>
