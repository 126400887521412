<script setup lang="ts">
import { computed, onBeforeUnmount, ref, watch } from 'vue';
import api from '@/api';
import { FILE_NAME_SEARCH_API_DEBOUNCE_WAIT_MS } from '@/constants';
import { DgrDialog, DgrIcon, DgrLoading } from '@stockmarkteam/donguri-ui';
import SurveySimpleUserDocumentItem from '@/components/survey/document/survey-simple-user-document-item.vue';
import { FileNameSearchResponse } from '@/types';
import { useUserDocumentActionHistories } from '@/utils/composables/useUserDocumentActionHistories';
import { debounce } from '@/utils/debounce';
import { useTeamInfo } from '@/utils/swr';
import { userSession } from '@/utils/userSession';

/**
 * Local State
 */

const SPECIFIED_FILES_LIMIT = 1;
const PAGING_LIMIT = 20;

// 契約状態
const { data: teamInfo } = useTeamInfo();
const enableUserDocument = computed(
  () => teamInfo.value?.enable_user_document ?? false,
);

// フィルターの開閉状態と操作
const isOpen = ref(false);
const openModal = () => (isOpen.value = true);

// フォームの値と操作
const fileSearchQuery = ref('');
const isComposing = ref(false); // IMEの変換状態を管理
const page = ref(1);
const foundedFiles = ref<Array<FileNameSearchResponse>>([]);
const hasMoreFiles = ref(true);
const modalBodyRef = ref<HTMLElement | null>();
const fileSearchInputRef = ref<HTMLInputElement | null>(null);
const specifiedFiles = defineModel('specifiedFiles', {
  default: [],
  required: true,
  type: Array<FileNameSearchResponse>,
});
const isLimited = computed(
  () => specifiedFiles.value.length >= SPECIFIED_FILES_LIMIT,
);
const isLoading = ref(false);
const isError = ref(false);

/* ファイル指定履歴と社内情報閲覧履歴 */
const {
  fetchLatestSpecifiedFileHistories,
  specifiedFileHistories,
  fetchLatestUserDocumentViewHistories,
  userDocumentViewHistories,
} = useUserDocumentActionHistories(userSession.getUserId());

watch(
  fileSearchQuery,
  debounce(async () => {
    if (isComposing.value) return;

    initializeState();
    if (fileSearchQuery.value.trim().length !== 0)
      await fileNameSearch(fileSearchQuery.value, page.value, PAGING_LIMIT);
  }, FILE_NAME_SEARCH_API_DEBOUNCE_WAIT_MS),
);

let observer: IntersectionObserver;
watch([isOpen, modalBodyRef], async ([isOpenVal, modalBodyVal]) => {
  if (isOpenVal && modalBodyVal) {
    await Promise.all([
      fetchLatestUserDocumentViewHistories(),
      fetchLatestSpecifiedFileHistories(),
    ]);

    const filesEl = modalBodyVal.querySelector('.files') as HTMLElement | null;
    if (!filesEl) return;

    observer = new IntersectionObserver(fetchMoreFiles, {
      root: filesEl,
      rootMargin: '0px',
      threshold: 0,
    });

    const sentinel = filesEl.querySelector('#sentinel');
    if (sentinel) {
      observer.observe(sentinel);
    }

    if (fileSearchInputRef.value) fileSearchInputRef.value.focus();
  }
});

onBeforeUnmount(() => {
  if (observer) observer.disconnect();
});

/**
 * Handlers
 */

const onInput = (event: Event) => {
  const target = event.target as HTMLInputElement;
  if (target) fileSearchQuery.value = target.value;
};

const onCompositionStart = () => (isComposing.value = true);

const onCompositionEnd = async () => {
  isComposing.value = false;
  // IME確定時には、1度ファイル検索APIを呼び出す
  if (fileSearchQuery.value.trim().length !== 0) {
    initializeState();
    await fileNameSearch(fileSearchQuery.value, page.value, PAGING_LIMIT);
  }
};

async function fileNameSearch(query: string, page: number, limit: number) {
  try {
    isLoading.value = true;

    const newFiles = await api.fileNameSearch(query, page, limit);
    if (newFiles.length === 0 || newFiles.length < limit)
      hasMoreFiles.value = false;

    foundedFiles.value = [...foundedFiles.value, ...newFiles];
  } catch (error) {
    isError.value = true;
    throw new Error(`ファイル検索に失敗しました: ${error}`);
  } finally {
    isLoading.value = false;
  }
}

const selectFile = (file: FileNameSearchResponse) => {
  if (isLimited.value) return;
  specifiedFiles.value = [file];
  isOpen.value = false;
};

const fetchMoreFiles = async (entries: IntersectionObserverEntry[]) => {
  if (isLoading.value) return;
  if (foundedFiles.value.length === 0 || !hasMoreFiles.value) return;

  const entry = entries[0];
  if (entry.isIntersecting) {
    if (!isError.value) page.value++;
    await fileNameSearch(fileSearchQuery.value, page.value, PAGING_LIMIT);
  }
};

const clearFileSearchQuery = () => (fileSearchQuery.value = '');

const initializeState = () => {
  foundedFiles.value = [];
  page.value = 1;
  hasMoreFiles.value = true;
  isError.value = false;
};
</script>

<template>
  <button
    class="file-specific-button"
    :class="{ 'disable-file-specific': !enableUserDocument || isLimited }"
    @click="openModal"
    :disabled="!enableUserDocument || isLimited"
  >
    <DgrIcon
      name="file-select"
      size="small"
      :keep-fill="false"
      class="file-specific-icon"
      :class="{ 'disable-file-specific': !enableUserDocument || isLimited }"
    />
    <span class="c-text c-text--m">ファイルを指定</span>
  </button>

  <DgrDialog
    v-model:open="isOpen"
    title="ファイル名検索"
    :modal="false"
    :has-footer="false"
  >
    <template #description>
      <div class="modal-body" ref="modalBodyRef">
        <div class="input-container">
          <input
            ref="fileSearchInputRef"
            class="file-search-input m-input c-text c-text--s c-textInput"
            placeholder="ファイル名を入力してください"
            @input="onInput"
            @compositionstart="onCompositionStart"
            @compositionend="onCompositionEnd"
            :value="fileSearchQuery"
          />
          <button
            v-if="fileSearchQuery.trim().length !== 0"
            @click="clearFileSearchQuery"
            class="clear-button c-text c-text--xs"
            aria-label="入力内容を削除"
          >
            入力をクリア
          </button>
        </div>
        <div class="files">
          <SurveySimpleUserDocumentItem
            v-for="file in foundedFiles"
            :key="file.id"
            :file="file"
            @select-file="selectFile"
          />
          <div id="sentinel"></div>
          <DgrLoading v-if="isLoading" class="loading-spinner" />
          <div
            v-if="foundedFiles.length > 0 && !hasMoreFiles && !isLoading"
            class="no-more-files-message c-text c-text--s"
          >
            これ以上、表示できるファイルはありません。
          </div>
        </div>
        <div
          v-if="
            fileSearchQuery.trim().length === 0 && foundedFiles.length === 0
          "
          class="user-document-action-histories"
        >
          <div
            v-if="specifiedFileHistories.length > 0"
            class="histories-container"
          >
            <p class="history-title c-text c-text--xs">
              <span>指定履歴</span>
            </p>
            <SurveySimpleUserDocumentItem
              v-for="history in specifiedFileHistories"
              :key="`specified-file-history-${history.id}`"
              :file="history"
              @select-file="selectFile"
            />
          </div>
          <div
            v-if="userDocumentViewHistories.length > 0"
            class="histories-container"
          >
            <p class="history-title c-text c-text--xs">
              <span>閲覧履歴</span>
            </p>
            <SurveySimpleUserDocumentItem
              v-for="history in userDocumentViewHistories"
              :key="`user-document-view-history-${history.id}`"
              :file="history"
              @select-file="selectFile"
            />
          </div>
        </div>
        <div
          v-if="
            fileSearchQuery &&
            foundedFiles.length === 0 &&
            !hasMoreFiles &&
            !isLoading
          "
          class="no-hit-files-message c-text c-text--s"
        >
          <span>{{ fileSearchQuery }}</span>
          に一致する検索結果は見つかりませんでした。
        </div>
        <div v-else-if="isError" class="error-message c-text c-text--s">
          エラーが発生しました。再度キーワードを入れ直してください。
        </div>
      </div>
    </template>
  </DgrDialog>
</template>

<style lang="scss" scoped>
.file-specific-button {
  display: flex;
  align-items: center;
  color: $color-gray1000;
  background: #fff;
  height: 32px;
  border: 1px solid $color-border;
  width: auto;
  justify-content: space-between;
  padding: 4px 16px;
  gap: 4px;
}

.file-specific-icon {
  flex-shrink: 0;
  fill: $color-gray1000;
}

.disable-file-specific {
  color: $color-gray400;
  fill: $color-gray400;
}

.modal-body {
  height: 480px;
  width: 565px;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

.input-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
}

.file-search-input {
  width: 100%;
  padding: 8px 104px 8px 8px;
  border-radius: 4px;
}

.clear-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px 16px;
  flex-shrink: 0;
  height: 24px;
  width: 92px;
  color: $color-gray800;
  &:hover {
    background-color: $color-gray400;
  }
}

.files,
.user-document-action-histories {
  overflow-y: scroll;
}

.histories-container {
  margin: 8px 0 8px;
}

.history-title {
  margin-bottom: 8px;
  color: $color-gray800;
}

.no-hit-files-message {
  margin: 8px 0;
  line-height: 16px;

  span {
    font-family: Noto Sans CJK JP;
    font-weight: 700;
  }
}

.no-more-files-message {
  margin: 16px auto;
  text-align: center;
  color: $color-gray600;
}

.error-message {
  margin: 8px 0;
  color: $color-orange1000;
}

#sentinel {
  height: 1px;
}

.loading-spinner {
  margin: 0 auto;
}
</style>
