import { pestNames, pests } from '@/constants';
import { NewsCategoryWithCount } from '@/types/newsCategory';

/**
 * ### PESTごとに分類したニュースカテゴリーのオブジェクトを生成する
 * 下記参考の全ニュースカテゴリー一覧の内、PESTに対応するニュースカテゴリーをマッピング
 * - 参考:
 *   - [全ニュースカテゴリー一覧](https://github.com/stockmarkteam/NewsCategoryClassification/tree/news-category/phase-1?tab=readme-ov-file#%E3%82%AB%E3%83%86%E3%82%B4%E3%83%AA%E4%B8%80%E8%A6%A7)
 *   - [分類の根拠](https://github.com/stockmarkteam/NewsCategoryClassification/blob/news-category/phase-1/category2pest_dic.py#L3)
 * 全ニュースカテゴリー一覧に沿って各記事に付与されたニュースカテゴリーが、上記分類の根拠の各PESTのいずれかの子要素の文言を含んでいれば、そのニュースカテゴリーはそのPESTに属すると判断される
 */
export const buildNewsCategoriesByPest = (): {
  [K in (typeof pests)[number]]: string[];
} =>
  ({
    politics: [
      '議案',
      '会議',
      '対談',
      '政府',
      '政府・予算',
      '政府・補助金',
      '政府・行革',
      '政府・認可',
      '政府・規制',
      '政府・宣言',
      '政府・会談',
      '要請',
      '税金',
    ],
    economy: [
      '市場動向',
      '業界情報',
      '情報公開',
      '市場データ',
      '業績',
      '支援',
      '投資',
      '設備投資',
      '資金',
      '生産活動',
      '営業活動',
      '受注',
      '価格',
      '価格変更',
      '株式概況',
      '提携',
      '海外展開',
      '経済',
      '買収',
      '契約',
      '為替',
      'サービス開始',
      'サービス更新',
      'サービス終了',
      '商品化',
      '対策',
      '施策',
      '設立',
      '組織',
      '組織変更',
      '人事',
      '方針',
      '戦略',
    ],
    technology: [
      '技術動向',
      '技術開発',
      '研究',
      '実験',
      '特許',
      '技術背景',
      '採用',
      '効果',
      '自然科学',
    ],
    society: [
      '社会統計',
      '社会情勢',
      '社会事件',
      '社会問題',
      '法廷',
      '表彰',
      '障害',
      '生活',
      '健康',
    ],
  }) satisfies {
    [K in (typeof pests)[number]]: string[];
  };
export type NewsCategoriesByPest = ReturnType<typeof buildNewsCategoriesByPest>;

export const groupNewsCategoriesByPest = (
  newsCategories: string[],
): NewsCategoriesByPest => {
  return [...pests].reduce((acc, pest) => {
    acc[pest] = acc[pest].filter(category => newsCategories.includes(category));
    return acc;
  }, buildNewsCategoriesByPest());
};

export const filterPestNewsCategories = (
  newsCategories: string[],
): string[] => {
  const allPestNewsCategories = Object.values(
    buildNewsCategoriesByPest(),
  ).flat();

  return newsCategories.filter(category =>
    allPestNewsCategories.includes(category),
  );
};

export const getActivityNewsCategoriesByName = (
  newsCategories: NewsCategoryWithCount[],
): Record<string, number> => {
  if (newsCategories === undefined) return {};

  return newsCategories.reduce((acc, cur) => {
    return {
      ...acc,
      [cur.name]: cur.count,
    };
  }, {});
};

export const getPestNewsCategoryFilterSections = (
  activityNewsCategoriesByName: Record<string, number>,
  maxCount?: number,
) => {
  const newsCategoriesByPest = buildNewsCategoriesByPest();

  return pests.map(pest => {
    return {
      sectionName: pestNames[pest],
      options: newsCategoriesByPest[pest]
        .flatMap(category => {
          if (activityNewsCategoriesByName[category] == null) return [];

          const count =
            maxCount === undefined
              ? activityNewsCategoriesByName[category]
              : Math.min(activityNewsCategoriesByName[category], maxCount);

          return {
            label: `${category}(${count})`,
            value: category,
          };
        })
        .sort((a, b) => {
          return activityNewsCategoriesByName[a.value]! >
            activityNewsCategoriesByName[b.value]!
            ? -1
            : 1;
        }),
    };
  });
};
