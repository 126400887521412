import { Store } from 'vuex';
import { RootState } from '@/stores/pcStore';
import axios from 'axios';
import Qs from 'qs';
import {
  DocType,
  FileNameSearchResponse,
  PostSurveyResponse,
  SearchScope,
  SurveyHistoryResponse,
  SurveySessionResponse,
} from '@/types';
import { STREAMING_DATA_TYPES } from '@/utils/survey/constants';
import { userSession } from '@/utils/userSession';

export async function* postSurvey(
  sessionId: string,
  question: string,
  searchScope: SearchScope,
  docTypes: Array<DocType | 'user_document'>,
  cachedExpandedQueries: string[],
  store: Store<RootState>,
  specifiedFiles: Array<FileNameSearchResponse>,
): AsyncGenerator<PostSurveyResponse> {
  if (globalThis.fetch === undefined) return; // テスト実行でfetchはundefinedなのでreturn

  try {
    store.commit('survey/setSurveyProgress', { sessionId, inProgress: true });

    // NOTE: v1の機能では要約結果をDBに保存しないため、`survey_session_id`には"new"という文字列を固定で送る
    // v2以降の開発が進んだ際には、`survey-top.vue`から`survey-session.vue`に遷移する前にスレッドの枠だけ作成 (`surveys`テーブルにデータ追加)し、その値を`survey_session_id`に渡すようにする
    // これにより、画面上のURLもサーバーサイドも古いフロントエンドリソースの互換性を考慮した上で開発が可能
    const response = await fetch(`api/v1/surveys/${sessionId}`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'text/event-stream',
      },
      method: 'POST',
      body: JSON.stringify({
        user_id: userSession.getUserId(),
        question,
        specified_files: specifiedFiles.length > 0 ? specifiedFiles : undefined,
        search_scope: searchScope,
        doc_types: docTypes.length > 0 ? docTypes : undefined,
        cached_expanded_queries: cachedExpandedQueries,
        streaming: true,
      }),
    });

    let chunk = '';
    const reader = response.body?.getReader();
    const decoder = new TextDecoder('utf-8');
    if (response.status !== 200 || !reader) {
      if (reader) {
        let jsonData = { error: undefined };
        try {
          const { value } = await reader.read();
          const data = decoder.decode(value, { stream: true });
          jsonData = JSON.parse(data.trim());
        } catch {
          // この下にあるthrowでエラーを投げる
        }
        throw new Error('リクエストに失敗しました', {
          cause: jsonData.error,
        });
      }
      throw new Error('リクエストに失敗しました');
    }
    while (true) {
      const { done, value } = await reader.read();

      if (done) break;
      if (!value) continue;

      chunk += decoder.decode(value, { stream: true });
      if (!chunk.includes('\n')) continue; // データの最後に改行がない場合はまだデータが完全ではないので次のデータを待つ

      const lines = chunk.split('\n');
      chunk = lines.pop() ?? ''; // 最後の行がまだ完全でない可能性があるため、それを次のチャンクと結合

      for (let line of lines) {
        if (!line.startsWith('data: ')) continue;
        line = line.slice(6); // `data: `部分を取り除く

        try {
          const json = JSON.parse(line.trim());

          if (Object.values(STREAMING_DATA_TYPES).includes(json.type))
            yield json;
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(
            'ストリーミングデータの JSONパースに失敗しました',
            error,
          );
          break;
        }
      }
    }
    reader.releaseLock();
  } finally {
    store.commit('survey/setSurveyProgress', { sessionId, inProgress: false });
  }
}

export const fetchSurveyHistories = async (
  surveySessionId: string,
  page: number,
  limit: number,
  order: 'desc' | 'asc', // created_atの昇順・降順を指定
) => {
  const params = {
    page,
    limit,
    order,
  };

  const { data } = await axios.get<Array<SurveyHistoryResponse>>(
    `/surveys/${surveySessionId}?${Qs.stringify(params, {
      arrayFormat: 'brackets',
    })}`,
  );
  return data;
};

export const createSurveySession = async (question: string) => {
  const { data } = await axios.post<SurveySessionResponse>(`/surveys`, {
    title: question,
  });
  return data;
};

export const fetchSurveys = async (page: number, limit: number) => {
  const params = { page, limit };

  const { data } = await axios.get(
    `/surveys?${Qs.stringify(params, {
      arrayFormat: 'brackets',
    })}`,
  );
  return data;
};

export const deleteSurveySession = async (surveySessionId: string) => {
  await axios.delete(`/surveys/${surveySessionId}`);
};

export const postSurveyFeedback = async (
  surveySessionId: string,
  requestId: string,
  evaluation: 'positive' | 'negative',
  reasons?: string[],
  comment?: string,
): Promise<void> => {
  await axios.post<void>(
    `/surveys/${surveySessionId}/histories/${requestId}/feedback`,
    {
      evaluation,
      reasons,
      comment,
    },
  );
};

export const deleteSurveyFeedback = async (
  surveySessionId: string,
  requestId: string,
): Promise<void> => {
  await axios.delete<void>(
    `/surveys/${surveySessionId}/histories/${requestId}/feedback`,
  );
};
